// Alternupsell v2.3
import { UpsellFunnelSettingsBase } from 'src/pages/funnel-settings-base/upsell-fs-base'

import { NavParams } from 'src/app/components/ionic-replacements/NavParams';
import { ToastController } from 'src/app/components/ionic-replacements/ToastController';
import { LoadingController } from 'src/app/components/ionic-replacements/LoadingController';

import { Component, ElementRef, HostListener, NgZone } from '@angular/core';

import { NavControllerExt } from 'src/app/extensions/nav-controller-extension';
import { OrderDetailsProvider } from 'src/app/providers/order-details/order-details';
import { TouchcrApiShippingProvider } from 'src/app/providers/TouchcrApiShippingProvider'
import { TouchcrApiRemoteServicesProvider } from 'src/app/providers/touchcr-api-remoteservices';
import { CalculateProvider } from 'src/app/providers/calculate/calculate';
import { UrlsProvider } from 'src/app/providers/urls';
import { TouchcrApiOrderformProvider } from 'src/app/providers/touchcr-api-orderform/touchcr-api-orderform';
import { GeneralProvider } from 'src/app/providers/general';
import { TouchcrApiGeneralExtenderProvider } from 'src/app/providers/touchcr-api-general/touchcr-api-general-extender';
import { TouchcrApiOrderExtenderProvider } from 'src/app/providers/touchcr-api-order/touchcr-api-order-extender';
import { EventStreamerProvider } from 'src/app/providers/event-stream';
import { PaypalPaymentExtenderProvider } from 'src/app/providers/paypal/paypal-extender';
import { StateProvider } from 'src/app/providers/state';
import { FunnelSettingsProvider } from 'src/app/providers/funnel-settings';
import { SpinnerProvider } from 'src/app/providers/spinner/spinner';
import { ModalController } from '../components/ionic-replacements/ModalController';
import { animate, style, transition, trigger } from '@angular/animations';


const BRAND = (window as any)['process_env'].BRAND;

@HostListener('unloaded')
@Component({
  selector: 'upsell-common',
  templateUrl: './up-down-sell.html',
  styleUrls: ['./up-down-sell.scss'],
/*   animations: [
    transition('* <=> *', [
    trigger('fadeAnimation', [
      transition('* <=> *', [
        style({ opacity: 0 }),
        animate(5000, style({ opacity: 1 }))
      ])
    ])
  ])
  ] */
})

export class UpsellCommon extends UpsellFunnelSettingsBase {
  public containsRawHTML: any = null;
  public containsRawCssStyles: any = null;
  public rawHTML: any = null;
  public rawHTMLMobile: any = null;
  public rawHTMLTablet: any = null;
  public rawHTMLVariant: any = null;
  public rawHTMLMobileVariant: any = null;
  public rawHTMLTabletVariant: any = null;

  public dialog: any;

  private spinnerKeyFrames: string = '';
  private rotationStartDegrees: string;
  private rotationDegrees: string = '4215';

  orderProducts: any[] = [];
  toggleSummary: boolean = false;
  totalTax: number = 0;
  shippingSummary: number = 0;
  totalProductsSummary: number = 0;
  minForFreeShipping: number = 99;
  show_progress_bar: boolean = false;
  progressBarLogo: string = 'https://cdn.gundrymd.com/images/GundryMD-Logo_short.png';
  progressBarTitle: string = '<span>Free Shipping!</span>'
  progressBarSubTitle: string = '<span>on U.S. orders over $99</span>'
  progressBarCustomText: string = 'Free Shipping!'
  exitpop: any = false;
  spintest: any = false;
  override buttonList: any = [
    {
      buttonId: 'AmazonPayButton',
      walletId: 'walletWidgetDiv',
      isPlaceOrder: true,
    },
    {
      buttonId: 'AmazonPayButton1',
      walletId: 'walletWidgetDiv1',
      isPlaceOrder: true,
    },
    {
      buttonId: 'AmazonPayButton2',
      walletId: 'walletWidgetDiv2',
      isPlaceOrder: true,
    },
    {
      buttonId: 'AmazonPayButton3',
      walletId: 'walletWidgetDiv3',
      isPlaceOrder: true,
    },
    {
      buttonId: 'AmazonPayButton4',
      walletId: 'walletWidgetDiv4',
      isPlaceOrder: true,
    },
    {
      buttonId: 'AmazonPayButton5',
      walletId: 'walletWidgetDiv5',
      isPlaceOrder: true,
    },
    {
      buttonId: 'AmazonPayButton6',
      walletId: 'walletWidgetDiv6',
      isPlaceOrder: true,
    },
    {
      buttonId: 'AmazonPayButton7',
      walletId: 'walletWidgetDiv7',
      isPlaceOrder: true,
    },
    {
      buttonId: 'AmazonPayButton8',
      walletId: 'walletWidgetDiv8',
      isPlaceOrder: true,
    },
    {
      buttonId: 'AmazonPayButton9',
      walletId: 'walletWidgetDiv9',
      isPlaceOrder: true,
    }
  ];


  // Global
  productBrand: string;
  productTitle: string;
  productName: string;
  productImage: string;
  productBottles: number;
  productBottlesUp: number;
  productQuantity: number;
  productUnitType: string;
  productQuantityNextUp: number;
  initialProduct: string;
  product_brand: string = '';
  product_title: string = '';
  product_name: string = '';
  product_image: string = '';
  product_bottles: string = '';
  product_bottles_up: string = '';
  auxImg: any = null;
  auxImgAlt: string = '';

  // Product
  productRegularPrice: number;
  productYourPrice: number;
  productInstantSavings: number;
  productPercent: number;
  product_regularPrice: number = 0;
  product_yourPrice: number = 0;
  product_instantSavings: number = 0;
  product_percent: number = 0;
  product_box_yourPriceText: string = ""
  product_box_instantSavingsText: string = ""
  product_box_regularText: string = ""

  // Header
  icon: string = '';
  showHeaderLogo: boolean = true;
  phone: string = '';
  displayPhone: boolean = true;
  showHeaderContent: boolean = true;
  phoneIcon: string = '';
  showHeaderOnUpsell: boolean = true;

  // Steps
  steps_show: boolean = false;
  steps_items: Array<any> = [];
  step_label: string = 'step'

  // Wait
  wait_show: boolean = false;
  wait_isFull: boolean = false;
  wait_title: string = '';
  wait_description: string = '';
  wait_text: string = 'wait!';
  iconImgWait: string = 'https://cdn.gundrymd.com/images/palm_wait.svg';

  // Section 01
  section_01_show: boolean = false;
  section_01_content: Array<any> = [];

  // product blurg
  sectionProductText: Array<any> = [];

  // Product box
  product_box_show: boolean = false;
  product_box_title: string = '';
  product_box_showTitle: boolean = false;
  product_box_subTitle: string = '';

  // Product box full
  product_box_full_show: boolean = false;
  product_box_full_title: string = '';
  product_box_full_additionalImage: string = '';


  // Garantee 01
  showButton: boolean = false;
  garantee_01_show: boolean = false;
  garantee_01_buttonYes: string = '';
  garantee_01_showButtonYes: boolean = true;
  garantee_01_buttonNo: string = '';
  garantee_01_showButtonNo: boolean = true;
  garantee_01_description: string = '';
  garantee_01_showDescription: boolean = false;
  garantee_01_image: string = '';
  garantee_01_imageAlt: string = '';
  garantee_01_showImage: boolean = true;
  garantee_01_upsellCtaTextButtonsClass: string;
  garantee_02_upsellCtaTextButtonsClass: string;

  // Section 02
  section_02_show: boolean = false;
  section_02_content: Array<any> = [];

  // section 03 and 4
  section_03_content: Array<any> = [];
  section_04_content: Array<any> = [];

  // Garantee 02
  garantee_02_show: boolean = false;
  garantee_02_buttonYes: string = '';
  garantee_02_showButtonYes: boolean = true;
  garantee_02_buttonNo: string = '';
  garantee_02_showButtonNo: boolean = true;
  garantee_02_description: string = '';
  garantee_02_showDescription: boolean = false;
  garantee_02_image: string = '';
  garantee_02_imageAlt: string = '';
  garantee_02_showImage: boolean = true;

  // Footer
  footerLinks: Array<any> = [];
  footerText: string;
  footerAddress: string;
  address: string;
  copyrightText = 'Copyright © 2024 Beverly Hills MD. All Rights Reserved.';
  footer_links: Array<any> = [];
  footer_text: string = '';
  footer_address: string = '';

  isStorageAvailable: boolean = true;

  // faq doc section
  faqDocSection: string;

  // BHMD Variables imported from the pre-1.18
  // TOUC-1036
  product_unit_type: string = '';
  product_quantity: number;
  content5: any;

  // reviews
  reviews: any[] = [
    {
      title: `<p class="reviews_titleText">"Excellent Product"</p>`,
      author: `<p class="reviews_authorText"><span class="bold">Name:</span> Elsa</p>`,
      date: '',
      content: `<p class="reviews_contentText">I feel so much better after taking both the Prebio-Thrive and the 24 strain Probiotic. I don't feel bloated, I am loving these products.</p>`,
      verified: true,
      stars: 1
    },
    {
      title: `<p class="reviews_titleText">"Great Product"</p>`,
      author: `<p class="reviews_authorText"><span class="bold">Name:</span> Lori Vannice</p>`,
      date: '',
      content: `<p class="reviews_contentText">This is a great product. I have only been on it for a month, but I am seeing good results. Have had problems for ten years, and finally my stomach is not blowing up all the time. I love the product</p>`,
      verified: true,
      stars: 1
    },
    {
      title: `<p class="reviews_titleText">"The Best Probiotics"</p>`,
      author: `<p class="reviews_authorText"><span class="bold">Name:</span> Marlene S. Stratton</p>`,
      date: '',
      content: `<p class="reviews_contentText">Since I have been taking the 24 hour Probiotics I feel so much better and I am feeling great. Thank you Dr. Gundry for making a beautiful pure product.</p>`,
      verified: true,
      stars: 1
    },
    {
      title: `<p class="reviews_titleText">"Amazing"</p>`,
      author: `<p class="reviews_authorText"><span class="bold">Name:</span>Judy</p>`,
      date: '',
      content: `<p class="reviews_contentText">This is the best probiotic I have ever tried. It has really improved my digestion after a stomach infection, and now I have no issues.</p>`,
      verified: true,
      stars: 1
    },
    {
      title: `<p class="reviews_titleText">"The Probiotic Did Solve My Issues"</p>`,
      author: `<p class="reviews_authorText"><span class="bold">Name:</span>Gina Jungles</p>`,
      date: '',
      content: `<p class="reviews_contentText">Best results comparing to others used.It's a happy family enjoying the benefits. Amazing knowledge behind this brand!!</p>`,
      verified: true,
      stars: 1
    }
  ];
  reviewsTitle: string;
  perReviewAddOn: string;

  initTimer: any = new Date().getTime(); // TOUC-982
  scrollEvents: any = 0; // TOUC-982
  rightSideContent: string = `<h2><strong>WAIT</strong>... YOUR ORDER IS NOT COMPLETE!</h2>
                               <p>Do not hit the "back" button, it can cause multiple charges on your card </p>`;
  pageContents: Array<IPageContent>;

  yesButton: string = 'https://cdn.gundrymd.com/images/upYes.jpg';
  yesButtonText: string = 'YES! I WANT THIS SPECIAL ADDED TO MY ORDER'; // TOUC-1243
  noButton: string = 'https://cdn.gundrymd.com/images/upNo.jpg';
  noButtonText: string = 'NO THANKS, I UNDERSTAND THAT I WON\'T SEE THIS OFFER AGAIN'; // TOUC-1243
  yesButtonMobile: string = 'https://cdn.gundrymd.com/images/upYes_mobile.jpg';
  yesButtonMobileText: string = 'YES! I WANT THIS SPECIAL ADDED TO MY ORDER'; // TOUC-1243
  noButtonMobile: string = 'https://cdn.gundrymd.com/images/upNo_mobile.jpg';
  noButtonMobileText: string = 'NO THANKS, I UNDERSTAND THAT I WON\'T SEE THIS OFFER AGAIN'; // TOUC-1243

  swapFormat: boolean;
  whiteLogo: string = "";
  brand: string;

  orText: string;
  textBelow: string;
  upsellDescription: string;
  clickText: string;
  shippingAndHandlingText: string;
  moneyBackBadge: string;
  showPercentage: boolean;
  showDiffSavings: string;
  autoplay: boolean;

  stepOptions: any = {
    imageFirstStep: 'https://cdn.gundrymd.com/images/gray_check2.png',
    imageSecondStep: 'https://cdn.gundrymd.com/images/blue_check.png',
    firstStepText: 'STEP 1',
    firstStepDescription: 'ORDER APPROVED',
    secondStepText: 'STEP 2',
    secondStepDescription: 'PRODUCT OPTIONS',
    thirdStepText: 'STEP 3',
    thirdStepDescription: 'FINAL CONFIRMATION'
  };


  mintercnt = 0; //TOUC-1202



  beforeunloadHandler: any; // TOUC-1251
  visibilityStateChangeHandler: any;
  saveduserHandler: any;
  confirmationMessage: string = 'It looks like you have been editing something. ';
  plannedNav: boolean = false;

  videoPlayType: string = "default";

  upsellVideoId: string;
  videoID: string;

  originalPurchaseQuantity: number;

  override braintreeCurrencyConversion: number = 1; // TOUC-14710
  override currencySymbol: string = "$"; // TOUC-14710
  currency: string = 'USD'; // TOUC-14710
  spinningState: string;
  closeAfterSpinTime: any;
  title: string;
  description: string;
  spinButtonText: string;
  spinnedTitle: string;
  spinnedDescription: string;
  spinnedButtonText: string;
  declineButtonText: string;
  wheelCenterImage: string;
  wheelOptions: { title: string; description: string; actualOffer: boolean; }[];
  pointerAngle: number;

	defaultContent: Object = {}; //TODO: Fill out with default content
	public randomVariant: number = -1;
	public dynamicContentTransformedDataFromApi: any = null;
	public isMobile: boolean = false;
	public isTablet: boolean = false;
	cssStyles: string = '';
	cssStylesVariant: string = '';
	cssStylesMobile: string = '';
	cssStylesMobileVariant: string = '';
	cssStylesTablet: string = '';
	cssStylesTabletVariant: string = '';

  constructorInitialized: boolean = false;

  constructor(
    public override navCtrl: NavControllerExt,
    public override navParams: NavParams,
    public override tcrApi: TouchcrApiGeneralExtenderProvider,
    public override tcrApiOrder: TouchcrApiOrderExtenderProvider,
    public override loadingCtrl: LoadingController,
    public override eventStreamer: EventStreamerProvider,
    public override orderDetails: OrderDetailsProvider,
    public override paypalPayment: PaypalPaymentExtenderProvider,
    public override toastCtrl: ToastController,
    public override ngZone: NgZone,

    public override shippingProvider: TouchcrApiShippingProvider,
    public override tcrApiRemoteServices: TouchcrApiRemoteServicesProvider,
    public override calculateHelper: CalculateProvider,
    public override urlsHelper: UrlsProvider,
    public override stateProvider: StateProvider,
    public override tcrApiOrderform: TouchcrApiOrderformProvider,
    public override funnelProvider: FunnelSettingsProvider,
    public override generalProvider: GeneralProvider,
    public override spinner: SpinnerProvider,
    public elementRef: ElementRef

  ) {
    super(
      navCtrl,
      navParams,
      tcrApi,
      tcrApiOrder,
      loadingCtrl,
      eventStreamer,
      orderDetails,
      paypalPayment,
      toastCtrl,
      ngZone,

      shippingProvider,
      tcrApiRemoteServices,
      calculateHelper,
      urlsHelper,
      stateProvider,
      tcrApiOrderform,
      funnelProvider,
      generalProvider,
      spinner,
    );

    

    if (!(window as any)['windowInitialized'] && !this.constructorInitialized) {
      (window as any)['windowInitialized'] = true;
      this.constructorInitialized = true;
      try {
        let il = 0;
        let dc = sessionStorage.getItem('braintreeDefaultCountry');
        setTimeout(() => {
          delete (window as any)['windowInitialized'];
        }, 1000);
        // TODO https://support.google.com/translate/thread/260113430/google-translate-script-on-website-for-comercial-use?hl=en
        if (sessionStorage.getItem('braintreeLanguageTranslation') == 'true' && dc) {
          if (this.urlsHelper.getParameterFromUrl({ url: location.search, parameter: 'step' }) != '2') {
            setTimeout(() => {
              this.toastCtrl.create({ message: `Translating...`, duration: 1000, position: 'top', cssClass: 'successToast' });
              this.spinner.enable('Translating...');
              setTimeout(() => {
                this.spinner.disable();
              }, 1000);
            }, 150);
          }
          if (!document.querySelector('#google_translate_script')) {
            // get ready for the translation
            let sty = document.createElement('style');
            sty.id = 'google_translate_content_delay';
            sty.innerHTML = `.spinnerInformation, .upsellDownsell {
            animation:0.5s ease 1s normal forwards 1 fadein;
            -webkit-animation:0.5s ease 1s normal forwards 1 fadein;
            opacity:0;
            }
            @keyframes fadein{from{opacity:0}
              to{opacity:1}
            }
            @-webkit-keyframes fadein{from{opacity:0}
              to{opacity:1}
           }
          `;
            document.head.appendChild(sty);
            if (dc == 'United States') il = 1;
            else if (dc == 'France') il = 2; 
            else if (dc == 'Germany') il = 3;
            else if (dc == 'Portgual') il = 4;
            else if (dc == 'Brazil') il = 4;
            else if (dc == 'Mexico') il = 5;
            let langstr = 'en,fr,de,pt,es';
            console.log('[GH] setting google translate to ' + dc + ' ' + il, langstr);
            let gscript = document.createElement('script');
            gscript.type = 'text/javascript'; 
            document.cookie = 'googtrans=;expires=Thu, 01-Jan-70 00:00:01 GMT;';
            gscript.innerText = `function googleTranslateElementInit() {
              if(document.querySelector('.spinnerWheel')) {
                console.log('notranslate spinnerWheel');
                document.querySelector('.spinnerWheel').classList.add('notranslate');
              }
              new google.translate.TranslateElement({ pagelLanguage: "en", includedLanguages: "${langstr}", layout: google.translate.TranslateElement.InlineLayout}, 'google_translate_element');
              var gtint = setInterval(() => {
                console.log('[GH] checking for google translate',document.querySelector('.goog-te-combo').options.length);
                if(  document.querySelector('.goog-te-combo') && document.querySelector('.goog-te-combo').options.length >= 5) {
                  window.clearInterval(gtint);
                    console.log('[GH] inside setting google translate to ${il}', document.querySelector('.goog-te-combo'));
                    document.querySelector('.goog-te-combo').options.selectedIndex = ${il};
                    document.querySelector('.goog-te-combo').dispatchEvent(new Event('change'));
/*                   setTimeout(() => {
                    document.querySelectorAll('.skiptranslate')[1].classList.toggle('skiptranslate_slideup');
                    document.querySelector('#spinner-specific-styles').nextSibling.classList.toggle('hidden');
                  }, 3000); */
                }
              }, 10);
              }`;
            document.head.appendChild(gscript);
            let gtscript = document.createElement('script');
            gtscript.id = 'google_translate_script';
            gtscript.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
            gtscript.type = 'text/javascript';
            document.head.appendChild(gtscript);
          }
        }
      } catch (e) {
        console.log('[GH] unable to add google translate script', e);
      }


      //TOUC-1525
      (window as any)['spintest'] = this.spintest;

      this.urlParameters = urlsHelper.getDataFromUrl(window.location.search);
      this.urlParameters.brand = BRAND;
      //Check if storage is unavailable - TCR
      try {
        sessionStorage.setItem('StorageTest', '');
        sessionStorage.removeItem('StorageTest');
      } catch (e) {
        this.isStorageAvailable = false;
      }


      try { // TOUC-1251 v1.24.9.1
        if (!this.beforeunloadHandler && window.addEventListener) {
          this.visibilityStateChangeHandler = (e: any) => {
            console.log(`Tab state : ${document.visibilityState}`);
            this.sendCustomEvent(`visibilitychange ${document.visibilityState}`, `Tab state : ${document.visibilityState}`);
          };
          window.addEventListener("visibilitychange", this.visibilityStateChangeHandler);
          console.log('added visibilitychange handler');
          this.beforeunloadHandler = (e: any) => {
            if (!this.exitpop) return; //TOUC-1490
            this.sendCustomEvent('beforeunload');
            console.log('emitted beforeunload event');
            this.saveduserHandler = () => {
              window.removeEventListener("focus", this.saveduserHandler);
              setTimeout(() => {
                this.sendCustomEvent('saveduser');
                console.log('emitted saveduser event');
                this.spinner.disable();
              }, 1000);
            };
            window.addEventListener("focus", this.saveduserHandler);
            console.log('added focus handler');
            if (this.plannedNav) return null;

            (e || window.event).returnValue = this.confirmationMessage;
            return this.confirmationMessage;
          };
          window.addEventListener("beforeunload", this.beforeunloadHandler);
          console.log('added beforeunload handler');
        }
      } catch (e) {
        console.log("unable to setup TOUC-1251 handlers: ", e);
      }




      if (this.randomVariant == -1) this.randomVariant = Math.floor(Math.random() * 2);  // 0 control, 1 variant
      if (this.urlsHelper.getParameterFromUrl({ url: location.search, parameter: 'dynamicversion' }) == 'control') this.randomVariant = 0;
      if (this.urlsHelper.getParameterFromUrl({ url: location.search, parameter: 'dynamicversion' }) == 'variant') this.randomVariant = 1;



      if (typeof (window as any)['upsellDynamicCMSControl'] == 'object') {
        let dynamicPageNamesWithContent = (window as any)['upsellDynamicCMSControl'].dynamicPageNamesWithContent;
        for (let i = 0; i < dynamicPageNamesWithContent.length; i++) {
          if (dynamicPageNamesWithContent[i] == `upDownDyn-${this.getUpsellPathName()}.json`) {
            let dataFromApi = null;
            dataFromApi = this.getDynamicContent(`/assets/content/upDownDyn-${this.getUpsellPathName()}.json`);
            this.addCss();
          }
        }
      }

    }


  }

  async getDynamicContent(url = '') {
		try {
			url += document.location.search;
			// TOUC-4724-BHMD
			// TODO make this use a configuration for the domain names
			if ((window as any)['process_env'] && (window as any)['process_env'].FUNNEL_URL && (window as any)['process_env'].FUNNEL_URL.indexOf('uat-') > -1) {
				console.log("[GH][CMS] UAT Edit JSON Content file Control: ", "https://alterncms.herokuapp.com/editor.html?name="  + url.replace("/assets/content/", "").replace('?','&') +  "&brand=" + encodeURIComponent((window as any)['process_env'].BRAND) + "&dynamicversion=control");
				console.log("[GH][CMS] UAT Edit JSON Content file Variant: ", "https://alterncms.herokuapp.com/editor.html?name="  + url.replace("/assets/content/", "").replace('?','&') +  "&brand=" + encodeURIComponent((window as any)['process_env'].BRAND) + "&dynamicversion=variant");
			} else {
				console.log("[GH][CMS] UAT Edit JSON Content file Control: ", "https://alterncms-ps.herokuapp.com/editor.html?name="  + url.replace("/assets/content/", "").replace('?','&') +  "&brand=" + encodeURIComponent((window as any)['process_env'].BRAND) + "&dynamicversion=control");
				console.log("[GH][CMS] UAT Edit JSON Content file Variant: ", "https://alterncms-ps.herokuapp.com/editor.html?name="  + url.replace("/assets/content/", "").replace('?','&') +  "&brand=" + encodeURIComponent((window as any)['process_env'].BRAND) + "&dynamicversion=variant");
			}
			let content = {}
			let xhr = new XMLHttpRequest();
			xhr.open("GET", url, false);
			xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
			xhr.onload = () => {
				if (xhr.readyState === 4 && xhr.status === 200) {
					//console.log('[GH] xhr response', xhr.response);
					content = xhr.response;
					if(typeof xhr.response == 'string') content = JSON.parse(xhr.response);
					this.funnelProvider.sendCustomStats("CMS - XHR Success",{ "message": `Success: ${JSON.stringify(content).length}` });
					if (Object.keys(content).length === 0) {
						throw new Error("Error getting upsell dynamic content")
					}
					let dataFromApi = content;
					this.dynamicContentTransformedDataFromApi  = true;
					//console.log('[GH] this.dynamicContentTransformedDataFromApi ' + typeof dataFromApi, dataFromApi);
					if(typeof dataFromApi == 'string') dataFromApi = JSON.parse(dataFromApi);
					Object.entries(dataFromApi).forEach(([key, val]) => {
							(this as any)[key] = val;
							if(key == 'rawHTML') this.containsRawHTML = true;
							if(key == 'cssStyles') this.containsRawCssStyles = true;
						}
					);
					if(this.containsRawHTML &&  this.containsRawCssStyles ) {
						console.log('[GH] containsRawHTML and containsRawCssStyles using variant ' + this.randomVariant);
						this.addCss();
					}
					if(this.containsRawHTML ) {
						this.interopolateAndBind();
					}
					setTimeout(() => {
						this.spinner.disable();
					}, 300);
					return content;
				}
				return '';
			};

			xhr.onerror = (error) => {
				console.log(`XHR Error: ${error}`);
				this.funnelProvider.sendCustomStats(
					"CMS - XHR Error",
					{ "message": `(${this.urlsHelper.getNavigator()}) ${error}` }
				);

				throw new Error(`Error: ${error}`)
			}

			xhr.send();
			return content;
			

		} catch (err) {
			console.log(`Upsell/Downsell Content Error: ${err}`);
			this.funnelProvider.sendCustomStats(
				"CMS - Content Error",
				{
					"message": `Error pulling upsell dynamic content, returning default. (${this.urlsHelper.getNavigator()})`,
					"error": err
				}
			);

			return this.defaultContent;
		}
	}

  addCss() {
		let css = document.createElement('style');
		css.id="dynamicCss";
		if (this.randomVariant == 1) { // variant split test
			if (this.isTablet && this.cssStylesTabletVariant && this.cssStylesTabletVariant != '') {
				console.log('[GH] isTablet cssStylesTablet variant ');
				css.appendChild(document.createTextNode(this.cssStylesTabletVariant));
			} else if (this.isMobile && this.cssStylesMobileVariant && this.cssStylesMobileVariant != '') {
				console.log('[GH] isMobile cssStylesMobile variant ');
				css.appendChild(document.createTextNode(this.cssStylesMobileVariant));
			} else if (this.cssStylesVariant) {
					console.log('[GH] variant cssStylesVariant ');
					css.appendChild(document.createTextNode(this.cssStylesVariant));
			} else {
				console.log('[GH] variant uses control css cssStyles ');
				css.appendChild(document.createTextNode(this.cssStyles));
			}
			document.getElementsByTagName("head")[0].appendChild(css);
		} else { // control
			if (this.isTablet && this.cssStylesTablet && this.cssStylesTablet != '') {
				console.log('[GH] isTablet cssStylesTablet control ');
				css.appendChild(document.createTextNode(this.cssStylesTablet));
			} else if (this.isMobile && this.cssStylesMobile && this.cssStylesMobile != '') {
				console.log('[GH] isMobile cssStylesMobile control ');
				css.appendChild(document.createTextNode(this.cssStylesMobile));
			} else {
				console.log('[GH] variant cssStyles ');
				css.appendChild(document.createTextNode(this.cssStyles));
			}
			document.getElementsByTagName("head")[0].appendChild(css);
		}
	}
  
	public interopolateAndBind() {

		setTimeout(() => {					// split test in a day
			if (this.isMobile && this.rawHTMLMobile && this.rawHTMLMobile != '') {
				this.rawHTML = this.rawHTMLMobile;
				if (this.rawHTMLMobileVariant && this.rawHTMLMobileVariant != '') {
					if (this.randomVariant == 1) {
						this.rawHTML = this.rawHTMLMobileVariant;
					}
				}
			} else if (this.isTablet && this.rawHTMLTablet && this.rawHTMLTablet != '') {
				this.rawHTML = this.rawHTMLTablet;
				if (this.rawHTMLTabletVariant && this.rawHTMLTabletVariant != '') {
					if (this.randomVariant == 1) {
						this.rawHTML = this.rawHTMLTabletVariant;
					}
				}
			} else if (this.rawHTMLVariant && this.rawHTMLVariant != '') {
				if (this.randomVariant == 1) {
					this.rawHTML = this.rawHTMLVariant;
				}
			}

			console.log('[GH] dynamic values you can use like ${this.productQuantity}');
			this.rawHTML = this.interpolate(this.rawHTML, this);
			 (document as any).querySelector('.rawHTML').innerHTML = this.generalProvider.getsanitizedHTML(this.rawHTML);
			
			if(document.querySelector('.yesNoWrap_yesbutton')) {
				let btns = document.querySelectorAll('.yesNoWrap_yesbutton');
				for (let i = 0; i < btns.length; ++i) {
					(btns[i] as any).addEventListener('click', (ele:any) => this.orangesButtonClick(ele));
				}
			}
			if(document.querySelector('.gray_button')) {
				let btns = document.querySelectorAll('.gray_button');
				for (let i = 0; i < btns.length; ++i) {
					(btns[i] as any).addEventListener('click', (ele:any) => this.grayButtonClick(ele) );
				}
			}
		 }, 0);
	}

  
	orangesButtonClick(ele: any) {
		if (this.randomVariant == 0 || this.randomVariant == 1) {
			// split test in a day
			if (this.randomVariant == 0) this.sendCustomEvent('Control Yes Button Clicked');
			if (this.randomVariant == 1) this.sendCustomEvent('Variant Yes Button Clicked');
		}
		ele.outerHTML = ele.outerHTML;
		this.nextPage(true);
	}

	grayButtonClick(ele: any) {
		if (this.randomVariant == 0 || this.randomVariant == 1) {
			// split test in a day
			if (this.randomVariant == 0) this.sendCustomEvent('Control No Thanks Button Clicked');
			if (this.randomVariant == 1) this.sendCustomEvent('Variant No Thanks Button Clicked');
		}
		ele.outerHTML = ele.outerHTML;
		this.nextPage(false);
	}
  
	interpolate(str:any, obj:any) {
		console.log('[GH] interpolate obj ', obj);
		return new Function(`return \`${str}\`;`).call(obj);
		// seeing in CSS font family having quotes surrounding values, these need to be double escaped
	}

  static pluralize(word: string, quantity: number, plural = word + 's'): string {
    if (quantity === 1) return word;
    return plural;
  }
  sendCustomEvent(event_type: any, data?: any) {

    try { // TOUC-982

      // [TOUC-5700]
      if (document.location.search && document.location.search.length > 0 && typeof URLSearchParams === 'function') {
        let searchParams = new URLSearchParams(document.location.search);
        if (searchParams && searchParams.get("utm_campaign") && (searchParams as any).get("utm_campaign").indexOf("email") > -1) {
          console.log("[GH] not saving event " + event_type + " for email campaign " + searchParams.get("utm_source"), data);
          return;
        }
      }

      if (typeof data === 'undefined') {
        var regularPrice: any = document.querySelector('.regularPrice') ? document.querySelector('.regularPrice') : document.querySelector('.description_wrapper_regularPrice');
        var instantSavings: any = document.querySelector('.instantSavings') ? document.querySelector('.instantSavings') : document.querySelector('.description_wrapper_instantSavings');
        var yourPrice: any = document.querySelector('.yourPrice') ? document.querySelector('.yourPrice') : document.querySelector('.description_wrapper_yourPrice');

        if (!regularPrice) regularPrice = document.querySelector('.reg-price');
        if (!instantSavings) instantSavings = document.querySelector('.instant-savings');
        if (!yourPrice) yourPrice = document.querySelector('.special-price');

        if (!regularPrice) regularPrice = document.querySelector('.productDesc2');
        if (!instantSavings) instantSavings = document.querySelector('.productDesc3');
        if (!yourPrice) yourPrice = document.querySelector('.productPrice');


        if (regularPrice) regularPrice = regularPrice.textContent;
        if (instantSavings) instantSavings = instantSavings.textContent;
        if (yourPrice) yourPrice = yourPrice.textContent;
        var productinfo = yourPrice + ', ' + regularPrice + ', ' + instantSavings;
        if (productinfo.length > 180) productinfo = productinfo.substring(0, 180);
        var data = Object();
        data = {
          time: (new Date().getTime() - this.initTimer) / 1000,
          scrolls: this.scrollEvents,
          productinfo: productinfo
        };
      }
      const seg = document.location.pathname.replace(/\//g, '');
      const component = this.funnelProvider.getComponent(seg);
      if (typeof component !== 'undefined') {
        //console.log("sending Custom stats for " + component.name, data)
        this.funnelProvider.sendCustomStats(
          event_type,
          { segment: seg, name: component.name, data: data }
        );
      }
    } catch (e) {
      console.log('TOUC-982 error:', e);
    }
  }
  override payWithAmazon() {

  }
  nextPage(event: any) {
    //this.navCtrl.animate=true;
    console.log("NEXT PAGE TEST ", event);
    if (event) {
      if (this.beforeunloadHandler && window.removeEventListener) {
        window.removeEventListener("beforeunload", this.beforeunloadHandler);
      }
      this.beforeunloadHandler = (e:any) => { };
      this.sendCustomEvent('Upgrade Clicked');
      this.toastCtrl.create({ message: `Updating order...`, duration: 3000, position: 'top' })//.present();
      this.upgradeOrder();
    } else {
      this.sendCustomEvent('No Thanks Clicked');
      this.noThanks();
    }
    setTimeout(() => {
      this.navCtrl.animate = false;
    }, 1000);

    // this was when TCR owned the upsell base code
    // this.checkParams(); //TOUC-1202
  }

  public getUpsellPathName() {
    if (location.pathname.indexOf('{') > -1) return '';
    return location.pathname.substring(1);
  }
  public getHere() {
    return window.location.href ; // [TOUC-5682]
  }
  //Use to set custom title of the page

  public async ngAfterContentInit(): Promise<void> {
    const component = this.funnelProvider.getComponent(`${this.getUpsellPathName()}`);
    if (component) {
      this.eventStreamer.streamEvent("appScreenView", {
        screenPath: `${this.getUpsellPathName()}`,
        screenName: (document.querySelector('router-outlet') as any).nextElementSibling.tagName.toLowerCase().replace(/-([a-z])/g, function (m:any, w:any) {
          return w.toUpperCase();
        }),
        customTitle: component.name,
        fullUrl: `${this.getHere()}`,
      });
    }
    let titleId = document.getElementById('title');
    if (titleId) {
      titleId.innerHTML = this.upsell && this.upsell.product ? this.upsell.product.name : BRAND + ' - Preview Offer';
    }

    try {
      if (document.location.search.indexOf('unit_') > -1) {
        this.originalPurchaseQuantity = parseInt(document.location.search.substring(document.location.search.indexOf('unit_') - 2, document.location.search.indexOf('unit_')).replace('_', ''));
      }
    } catch (e) {
      console.log('trouble retrieving the original purchase quantity');
    }
    await this.funnelProvider.getAndApplyContentPage(this);
  }
  // Clear storage and create shipping invoice if leave page
  override ngOnDestroy() {
    super.ngOnDestroy();
       setTimeout(() => {
         this.elementRef.nativeElement.remove();
       }, 1);


    this.mintercnt = 9999;
    if (this.beforeunloadHandler) {
      window.removeEventListener("beforeunload", this.beforeunloadHandler);
    }
    if (this.saveduserHandler) {
      window.removeEventListener("focus", this.saveduserHandler);
    }
    if (this.visibilityStateChangeHandler) {
      window.removeEventListener("visibilitychange", this.visibilityStateChangeHandler);
    }
  }

  getPseudoState(sfid:any) {
    try {
      // TOUC-1243 prep a pseudo-state object if a developer is here
      // TODO improve this so data payload below is updated to match new tcr funnel_confs file format (so the right title/price show when in debugMode)
      if (localStorage.getItem('debugMode') === 'true') {
        const FUNNEL_CONFS = (window as any)['funnel_confs'] ? (window as any)['funnel_confs'].funnels : null;
        var currentUrl = document.location.pathname.replace(/\//g, '');
        let mainFunnel = null;
        const uniqueIdentifier = this.urlsHelper.getParameterFromUrl({
          url: window.location.href,
          parameter: "uid",
        });
        if (uniqueIdentifier) {
          mainFunnel = Object.keys(FUNNEL_CONFS)
            .map((key) => {
              return FUNNEL_CONFS[key];
            })
            .find((e) => e.uniqueIdentifier === uniqueIdentifier);
        } else {
          mainFunnel = Object.keys(FUNNEL_CONFS)
            .map((key) => {
              return FUNNEL_CONFS[key];
            })
            .find(
              (e) =>
                e.defaultFunnels &&
                e.defaultFunnels.find(
                  (e1:any) =>
                    e1.url === currentUrl ||
                    (e1.variants && e1.variants.find((e2:any) => e2.url === currentUrl)),
                ),
            );
        }


        console.log('mainFunnel ' + currentUrl, mainFunnel);
        var currentDefaultFunnel = mainFunnel.defaultFunnels.find((e:any) =>
          e.variants.find((el:any) => el.url === currentUrl),
        );
        if (!currentDefaultFunnel) currentDefaultFunnel = mainFunnel.defaultFunnels[0];
        var upsell = [currentDefaultFunnel.upsell];
        const step = this.urlsHelper.getParameterFromUrl({
          url: window.location.href,
          parameter: "step",
        });
        if (mainFunnel.defaultFunnels.length > 1) {
          for (var x = 1; x < mainFunnel.defaultFunnels.length; x++) {
            //console.log('[GH] checking upsells ' + x, mainFunnel.defaultFunnels[x]);
            currentDefaultFunnel = mainFunnel.defaultFunnels[x];
            if (currentDefaultFunnel.step && step && (parseInt(step)) === currentDefaultFunnel.step) {
              upsell = [];
              if (currentDefaultFunnel.upsell) upsell.push(currentDefaultFunnel.upsell);
              else if (currentDefaultFunnel.downsell) upsell.push(currentDefaultFunnel.downsell);
            }
          }
        }

        console.log('resulting upsell ' + currentUrl, upsell);
        // pseudo initial order below so you can view the upsell without placing an initial order
        var pp =
          '{"date":' +
          new Date().getTime() +
          ',"taxData":{"accountDetails":{"shipTo":{"line1":"address","city":"city","region":"CA","country":"US","postalCode":"90210"}},' +
          '"productDetails":[{"productDescription":"Developer productDescription","description":"Developer description","taxCode":null,"number":"01tf4000003VJk1AAG","quantity":1,"amount":123.45}],"shipping":0,"total":123.45,' +
          '"affParameters":{"utmSource":"","utmMedium":"","utmCampaign":"","utmCampaignId":"","utmContent":"","utmTerm":"","affId":"","offId":"","subId1":"","subId2":"","subId3":"","subId4":"","subId5":"","referralUrl":"","landingUrl":"","salesFunnel":"Funnel"},"isValid":true},' +
          '"pageName":"Upsell","orderDetails":{"order":{"subscriptionFrequencyMultiplier":null,"subscriptionFrequency":null,"subscriptionTax":null,"subscriptionShipping":null,"subscriptionSubtotal":null,"subscriptionId":null,' +
          '"orderItems":[{"productTaxCode":null,"productDescription":"Developer productDescription","productImage":"","isBumpOffer":false,"productVariantSize":null,"productVariantColor":null,"productVariantName":null,"productVariantPrice":null,"productVariantId":null,"productName":"Developer productName","productPrice":123.45,"productId":"01tf4000003VJk1AAG","totalPrice":123.45,"quantity":1,"orderId":"a0F0m000002aDr5EAE","order":"ORD-000140144","name":"Order-257118","id":"a0D0m000001YjoNEAS"}],' +
          '"orderTransactionFee":0,"tax":12.81,"brand":"Gundry MD","shippingZipPostal":"90210","additionalBillingAddressLine":null,"additionalShippingAddressLine":null,' +
          '"billingApartment":null,"billingAddress":"123 main","billingZip":"10002","billingState":"New York","billingCity":"new york","billingCountry":"United States","shippingApartment":null,"shippingAddress":"saddr changed","shippingState":"California","shippingCity":"city","shippingCountry":"United States","shipToName":"sfirst slast","orderShippingCost":0,"orderSource":"Funnel","parrentUpsellOrderId":null,"orderType":"Standard","latitude":null,"longitude":null,' +
          '"total":123.45,"status":null,"date":"Thu 05/12/2019 11:10","name":"ORD-000140144","id":"a0F0m000002aDr5EAE","paymentMethod":"Credit Card","customerEmail":"b@c.com","customerName":"sfirst slast","accountId":"0010m00000MqbDiAAJ",' +
          '"affParameters":{"affId":"","offId":"","subId1":"","subId2":"","subId3":"","subId4":"","subId5":""},' +
          '"subscribed":false,"authorized":false},"paymentMethod":"Standard",' +
          '"upsells":[' +
          JSON.stringify(upsell[0]) +
          '],' +
          '"paymentMethod":"Standard"}}';
        var pseudoStateConstruct = JSON.parse(pp);
        console.log('developer edition detected with empty state. ', pseudoStateConstruct);
        return pseudoStateConstruct;
      }
    } catch (e) {
      console.log('TOUC-962 error', e);
    }
  }


  public override async ngOnInit() {

    (window as any)['stepGateClosed'] = false;

    let body = document.getElementsByTagName('body')[0];
    body.setAttribute('data-qa','upsell-page');
    
  //async ionViewDidLoad() {
    //this.spinner.enable('Loading page.<br>Please Wait');
    try {
      this.initTimer = new Date().getTime(); // TOUC-902
      setTimeout(() => { this.sendCustomEvent('Sell Page View'); }, 1000); // TOUC-982
      (document.querySelector('body') as any).addEventListener('scroll', (e:any) => {
        this.scrollEvents++;
      });
    } catch (e) {
      console.log("TOUC-982 warning setting up scroll handler ", e);
    }

    this.isAmazonScriptLoaded = false;
    this.state = await this.stateProvider.get();
    this.show_progress_bar = true;
    this.orderProducts = []

    
    try { // TOUC-962
      if (!this.state || !this.state.orderDetails || (document.location.search && document.location.search.indexOf("pseudoState=true") > -1)) {
        if (localStorage.getItem("debugMode") == 'true') {
          this.state = this.getPseudoState('');
        }
      }
    } catch (e) {
      console.log("TOUC-962 error", e);
    }


    if (this.state.upsells) {
      this.orderProducts = [this.state?.orderDetails?.order, ...this.state?.upsells,]

      console.log('1abc', this.orderProducts)
    } else {
      this.orderProducts = [this.state?.orderDetails?.order]

      //console.log('2abc', this.orderProducts)
    }
    this.totalTax = Math.round((this.orderProducts.reduce((a, b) => {
      return a + b?.tax
    }, 0) + Number.EPSILON) * 100) / 100;
    this.totalProductsSummary = Math.round((this.orderProducts.reduce((a, b) => {
      return a + b.orderItems[0].totalPrice
    }, 0) + this.totalTax + Number.EPSILON) * 100) / 100;


    if (this.totalProductsSummary > this.minForFreeShipping) {
      this.totalProductsSummary = this.minForFreeShipping;
      this.progressBarTitle = `<span>Congratulations!</span>`
      this.progressBarSubTitle = `<span>You’ve Unlocked Free Shipping on your order.</span>`
    }
    console.log('total', this.totalProductsSummary)
    console.log("this.state", this.state);
    console.log(this.state)

    // [TOUD-5477]

    console.log("calling populatePageData with true");

    this.populatePageData(true);

    if (!this.state || !this.state.orderDetails) {
      const params: any = await this.funnelProvider.getPageByStep('first');
      if (!params) {
        return this.navCtrl.setRootExt('Home', { name: 'Home' });
      }
      const { component } = params;
      return this.navCtrl.pushExt(component.name, { name: component.name }).then(() => {
        return this.navCtrl.clearStack();
      });
    } else {
      try {
        (window as any)['USER_EMAIL'] = this.state.orderDetails.order.customerEmail;
        (window as any)['CONVERSION_ID'] = this.state.orderDetails.order.name;
        (window as any)['ORDER_TOTAL'] = this.state.orderDetails.order.total;
        console.log("conversion happened: " + (window as any)['USER_EMAIL'] + '::' + (window as any)['CONVERSION_ID'])
        console.log('attempting to set ctotal or cid cookies: ' + (window as any)['ORDER_TOTAL'] + ' ' + (window as any)['CONVERSION_ID']);
        document.cookie = 'ctotal=' + (window as any)['ORDER_TOTAL'] + '; expires=3600; path=/';
        document.cookie = 'cid=' + (window as any)['CONVERSION_ID'] + '; expires=3600; path=/';
      } catch (e) {
        console.log('unable to set ctotal or cid cookies: ' + e);
      }

      if (!this.isStorageAvailable && this.state.orderDetails.paymentMethod == 'PayPal') {
        return this.stateProvider.setPageNameAndOrder(this.stateProvider.ORDER_SUCCESS, this.state.orderDetails.order)
          .then(() => {
            console.log('redirect to order success');
            this.plannedLeavePage = true;
            this.sendCustomEvent(`THANK YOU`, 'paypal no storage');

            return this.navCtrl.setRootExt(this.stateProvider.ORDER_SUCCESS, { name: 'Order Success', isUpsell: true });
          })
      }
    }
    // Get shipping information from storage
    if (this.isShippingAvailable) {
      if (this.state.shippingInfo) {
        this.productsForShipping = this.state.shippingInfo.productsForShipping;
        this.isShippingValid = this.state.shippingInfo.isValid;
        this.accountInfoForShipping = this.state.shippingInfo.accountInfoForShipping;
        this.selectedDeliveryDay = this.state.shippingInfo.selectedDeliveryDay;
      } else {
        this.isShippingValid = false;
      }
    }
    if (this.taxServiceEnabled) {
      if (this.state.taxData) {
        this.isTaxValid = this.state.taxData.isValid;
      }
    }


    this.orderDetailsInfo = this.state.orderDetails;

    return this.tcrApi.getCountries().toPromise() //Get map of countries
      .then(async (countries:any) => {
        if (countries.length === 0) {
          console.log('getting a backup static copy of the country state payload');
          countries = await this.tcrApi.getCountriesDefault().toPromise();
        }

        if (countries) {
          countries.forEach((country:any) => {
            (this.countries as any)[country.label] = country;
          });
        }
      })
      .then(async () => {
        (window as any)['CheckoutBasePlaceOrder'] = { changePlaceOrder: this.changePlaceOrder.bind(this), zone: this.ngZone };
        (window as any)['buttonList'] = { buttonList: this.buttonList, zone: this.ngZone };
        (window as any)['upsellInfo'] = { isUpsell: true, zone: this.ngZone };
        return Promise.reject({
          allOk: true,
        });
      })
      .catch(async (error:any) => {
        await this.spinner.disable();
        await this.setData();
        if (!error.allOk) {
          console.log('Error', error);
          this.toastCtrl.create({ message: `Error! ${error.message}`, position: 'top', showCloseButton: true, cssClass: 'errorToast' })//.present();
        }
      })
  }

  async setData() {

    let paymentInProcess = false;
    if (!this.orderDetailsInfo.navParams) {
      this.orderDetailsInfo.navParams = {};
    }

    this.orderDetailsInfo.navParams.order = this.orderDetailsInfo.order;
    this.order = this.orderDetailsInfo.order;
    this.orderDetailsInfo.navParams.upsells = this.orderDetailsInfo.upsells;
    this.purchasedUpsells = this.orderDetailsInfo.purchasedUpsells || [];

    this.upsellIndex =
      this.orderDetailsInfo.upsellIndex === undefined ? -1 : this.orderDetailsInfo.upsellIndex;

    if (!this.orderDetailsInfo.navParams.paymentMethod && !this.urlParameters['resultCode']) {
      this.upsellIndex--;
    }

    this.orderDetailsInfo.navParams.paymentMethod = this.orderDetailsInfo.paymentMethod;
    this.isAmazon = this.orderDetailsInfo.paymentMethod === 'amazon';

    if (this.urlParameters['paypal'] || this.urlParameters['paypalnvp']) {
      paymentInProcess = true;

      this.orderDetailsInfo.navParams.paymentMethod = 'PayPal';
      if (this.urlParameters['action'] === 'execute') {
        if (this.urlParameters['paypalnvp']) {
          this.executePayPalNVP(this.urlParameters, this.purchasedUpsells[this.purchasedUpsells.length - 1]);
        } else {
          this.executePayPalPayment(this.urlParameters);
        }
      } else if (this.urlParameters['action'] === 'cancel') {
        let cancelOrder = this.purchasedUpsells.pop();
        this.paypalPayment.cancelPayment(cancelOrder.id).then(
          (result: any) => {
            if (result.message === 'success') {
              this.sendCustomEvent(`ORDER CANCELLED!`);

              this.toastCtrl
                .create({ message: `ORDER CANCELLED!`, duration: 3000, position: 'top' })
                //.present();
            }
          },
          () => {
            this.sendCustomEvent(`Error on cancelling order!`);

            this.toastCtrl
              .create({ message: `Error on cancelling order!`, duration: 3000, position: 'top' })
             // .present();
          },
        );
      }
      return;
    }

    if (this.orderDetailsInfo.navParams.upsells && this.orderDetailsInfo.navParams.order) {
      if (this.orderDetailsInfo.navParams.paymentMethod === 'Standard')
        this.paymentMethod.standard = true;


      if (this.orderDetailsInfo.navParams.paymentMethod === 'PayPal')
        this.paymentMethod.paypal = true;
      this.order = this.orderDetailsInfo.navParams.order;
      if (
        this.orderDetailsInfo.navParams.upsells &&
        this.orderDetailsInfo.navParams.upsells.length > 0
      ) {
        [this.upsell] = this.orderDetailsInfo.navParams.upsells;
      }
      if (this.taxServiceEnabled) this.showNext(false);
      if (!this.taxServiceEnabled) this.calculateTransactionFee();
    } else {
      const params: any = await this.funnelProvider.getPageByStep('first');
      if (!params) {
        return this.navCtrl.setRootExt('Home', { name: 'Home' });
      }
      const { component } = params;
      return this.navCtrl.pushExt(component.name, { name: component.name }).then(() => {
        return this.navCtrl.clearStack();
      });
    }



    console.log('[GH] currency setup : this.orderDetailsInfo', this.orderDetailsInfo);
    if (this.orderDetailsInfo.navParams.paymentMethod.startsWith ('Braintree ')) {  // TOUC-14710
      if(this.orderDetailsInfo.cr && this.orderDetailsInfo.order.crv) {
        if(this.orderDetailsInfo.cr != this.orderDetailsInfo.order.crv) {
          console.log('[GH] currency conversion rate changed');
          return this.navCtrl.setRootExt(this.stateProvider.ORDER_SUCCESS, { name: 'Order Success', isUpsell: true });
        }
        this.braintreeCurrencyConversion = parseFloat(this.orderDetailsInfo.cr);
      }

      this.paymentMethod.braintreeMethod = true;
      this.currency = this.orderDetailsInfo.navParams.paymentMethod.replace( 
        'Braintree ', ''
      );
      if(sessionStorage.getItem('currency')) (this.currency as any) = sessionStorage.getItem('currency');
      if(sessionStorage.getItem('currencySymbol')) (this.currencySymbol as any) = sessionStorage.getItem('currencySymbol');

      console.log('[GH] this upsell', this.upsell);
      console.log('[GH] this currency', this.currency);
      console.log('[GH] this currency symbol', this.currencySymbol);
    }

    console.log("calling populatePageData with false ok");
    this.populatePageData(false);

    setTimeout(() => {
      try {
        // listen to known navigation events, control what beforeunload handler does
        let links = document.getElementsByTagName('a');
        for (let lc = 0; lc < links.length; lc++) {
          window.addEventListener('click', (event) => {
            this.plannedNav = true;
            setTimeout(() => {
              this.plannedNav = false;
            }, 300);
          });
        }
      } catch (e) {
        console.log('error ' + e);
      }
    }, 1); // TOUC-1243

    // Pixel
    this.appendPixel();
    console.log('this.navParams', this.navParams);
    console.log('this.orderDetailsInfo', this.orderDetailsInfo);
    console.log('this.orderDetailsInfo.navParams', this.orderDetailsInfo.navParams);
    // TODO figure out the format of this data object
    //if (!this.navParams.data.isUpsell && !paymentInProcess) {
      this.generalProvider.disableBackButton();
    //}


  return;
  }

  // Global product
  setProductData() {
    // Global
    let product = this.upsell && this.upsell.product ? this.upsell.product : {};
    this.productBrand = product.productBrand && product.productBrand.uniqueName ? product.productBrand.uniqueName : '';
    this.productTitle = product.name ? product.name : '';
    // Per ticket DEV-23925, we fetch now displayOrder = 2 imageUrl, and if not found, we just proceed with the default logic
    const displayOrder2 = !!product.image.length && product.image.find((img: any) => img.displayOrder === 2);
    this.productImage = displayOrder2 && displayOrder2.imageUrl ? displayOrder2.imageUrl : (product.image && product.image.length > 0 && product.image[0].imageUrl && product.image[0].imageUrl.length > 0 ? product.image[0].imageUrl : '');
    this.product_image = displayOrder2 && displayOrder2.imageUrl ? displayOrder2.imageUrl : (product.image && product.image.length > 0 && product.image[0].imageUrl && product.image[0].imageUrl.length > 0 ? product.image[0].imageUrl : '');
    
    // this.productImage = product.image && product.image.length > 0 && product.image[0].imageUrl && product.image[0].imageUrl.length > 0 ? product.image[0].imageUrl : '';
    // this.product_image = product.image && product.image.length > 0 && product.image[0].imageUrl && product.image[0].imageUrl.length > 0 ? product.image[0].imageUrl : '';
    this.product_brand = product.productBrand && product.productBrand.uniqueName ? product.productBrand.uniqueName : '';
    this.product_title = product.name ? product.name : '';
    

    // Product
    this.productRegularPrice = this.upsell.standardPrice || 0;
    this.productYourPrice = this.upsell.offerPrice || 0;
    this.productInstantSavings = this.productRegularPrice - this.productYourPrice;
    this.productPercent = Math.floor(this.productInstantSavings * 100 / this.productRegularPrice) || 0;
    this.product_regularPrice = this.upsell.standardPrice || 0;
    this.product_yourPrice = this.upsell.offerPrice || 0;
    this.product_instantSavings = this.product_regularPrice - this.product_yourPrice;
    this.product_percent = Math.round(this.product_instantSavings * 100 / this.product_regularPrice) || 0;

    this.product_box_yourPriceText = 'Your Price';
    this.product_box_instantSavingsText = 'Instant Savings';
    this.product_box_regularText = 'Regular Price'
  }

  // Product
  setProductName() {
    this.productName = '';
    this.productBottles = 0;
    this.productBottlesUp = 0;
    this.product_name = '';
    this.product_bottles = '';
    this.product_bottles_up = '';
  }

  // Header
  setHeaderData() {
    // this.icon = '//cdn.activatedyou.com/wp-content/uploads/2019/03/logo_H%403x1.png';
    this.icon = 'https://cdn.activatedyou.com/wp-content/uploads/2019/03/logo_H%403x1.png';
    this.phone = `<span class="call-toll-free">Call Toll-Free!</span> 800-720-8403`;
    this.phoneIcon = 'https://cdn.gundrymd.com/images/phone.png';
  }

  // Steps
  setStepsData() {
    this.steps_show = true;
    this.steps_items = [
      {
        label: 'order approved',
        active: false,
        showCheck: true
      },
      {
        label: 'product options',
        active: true,
        showCheck: true
      },
      {
        label: 'final confirmation',
        active: false,
        showCheck: false
      }
    ];
  }

  // Wait
  setWaitData() {
    this.wait_show = false;
    this.wait_isFull = false;
    this.wait_title = '';
    this.wait_description = '';
    this.wait_text = 'wait!';
  }

  // Section 01
  setSection01Data() {
    this.section_01_show = false;
    this.section_01_content = [];
  }

  // Product box
  setProductBoxData() {
    this.product_box_show = false;
    this.product_box_title = '';
    this.product_box_showTitle = false;
    this.product_box_subTitle = '';
  }

  // Product box full
  setProductBoxFullData() {
    this.product_box_full_show = false;
    this.product_box_full_title = '';
    this.product_box_full_additionalImage = '';
  }

  // Garantee 01
  setGarantee01Data() {
    this.garantee_01_show = false;
    this.garantee_01_buttonYes = '';
    this.garantee_01_showButtonYes = true;
    this.garantee_01_buttonNo = '';
    this.garantee_01_showButtonNo = true;
    this.garantee_01_description = '';
    this.garantee_01_showDescription = false;
    this.garantee_01_image = '';
    this.garantee_01_imageAlt = '';
    this.garantee_01_showImage = true;
  }

  // section 01
  setSection01Data02Data() {
    this.sectionProductText
  }

  // Section 02
  setSection02Data() {
    this.section_02_show = false;
    this.section_02_content = [];
  }

  // Garantee 02
  setGarantee02Data() {
    this.garantee_02_show = false;
    this.garantee_02_buttonYes = '';
    this.garantee_02_showButtonYes = true;
    this.garantee_02_buttonNo = '';
    this.garantee_02_showButtonNo = true;
    this.garantee_02_description = '';
    this.garantee_02_showDescription = false;
    this.garantee_02_image = '';
    this.garantee_02_imageAlt = '';
    this.garantee_02_showImage = true;
  }

  // Footer
  setFooterData() {
    this.footer_links = [
      {
        label: 'Terms & Conditions',
        page: 'https://gundrymd.com/terms/',
        target: '_blank',
        options: 'width=400,height=400',
      },
      {
        label: 'Privacy Policy',
        link: '/PrivacyPolicy/',
        page: '/PrivacyPolicy/',
        target: '_blank',
        options: 'width=400,height=400',
      }
    ];
    this.footerLinks = this.footer_links;
    this.footerText = 'We work hard to formulate the most advanced products on the market. Our 90-Day Customer Satisfaction Guarantee is designed to give you ample opportunity to experience optimal results from your product, completely risk-free.';
    this.footerAddress = '9465 Wilshire Boulevard, Suite 300 Beverly Hills, California, 90212';
    this.footer_text = this.footerText;
    this.footer_address = this.footerAddress;
    this.footer_links = this.footerLinks;
  }

  populatePageData(flag: boolean) {
    let body = document.getElementsByTagName('body')[0];
    body.setAttribute('data-qa','upsell-page');
    
    if (flag) {
      this.setHeaderData();
      this.setStepsData();
      this.setWaitData();
      this.setFooterData();
    } else {
      this.setProductName();
      this.setProductData();
      this.setSection01Data();
      this.setProductBoxData();
      this.setProductBoxFullData();
      this.setGarantee01Data();
      this.setSection02Data();
      this.setGarantee02Data();
    }
  }

  /**
   *
   * This new string util functions are a test to enhance content
   * creation and editing in HTML string templates.
   *
   * @kemken077
   * Ticket: DEV-279
   *
   */
  getStrongText(str: string): string {
    return `<strong>${str}</strong>`;
  }

  getUnderlineText(str: string): string {
    return `<span class="underline">${str}</span>`;
  }

  getItalicText(str: string): string {
    return `<span class="italic">${str}</span>`;
  }

  
  dialogShowModal(dialog:any) {
    // console.log("dialoggg ", dialog);
    // this.dialog = dialog;
    // this.setSpinnerStyles();
    // console.log('dialogShowModal');
    // (dialog as any).showModal();
  }


  closeModal(event: any) {
    document.body.classList.remove('noscroll');
    // this.dialog.close();
    return new Promise<void>((resolve, reject) => {
      resolve();
    });
  }
  spinTheWHeel() {
    this.spinningState = 'spinning';
    console.log("Spininng state", this.spinningState);
    setTimeout( () => {
      this.spinningState = 'spinned';
      if ( this.closeAfterSpinTime ) {
        setTimeout( () => {
           this.closeModal(null);
        }, this.closeAfterSpinTime * 1000 );
      }
    }, 4999 );
  }

  setSpinnerStyles() {
    let spinnerData = {
      title: 'Spin the wheel',
      description: 'Spin the wheel to see if you can win a discount on your order today!',
      spinButtonText: 'Spin the wheel',
      spinnedTitle: 'You won!',
      spinnedDescription: 'You won a discount on your order today!',
      spinnedButtonText: 'Claim your discount',
      declineButtonText: 'No thanks, I don\'t want to spin the wheel',
      wheelCenterImage: 'https://cdn.gundrymd.com/images/logo-circle.png',
      wheelOptions: [
        {
          title: '10% off',
          description: '10% off your order today',
          actualOffer: true,
        },
        {
          title: '20% off',
          description: '20% off your order today',
          actualOffer: false,
        },
        {
          title: '30% off',
          description: '30% off your order today',
          actualOffer: false,
        },
        {
          title: '40% off',
          description: '40% off your order today',
          actualOffer: false,
        },
        {
          title: '50% off',
          description: '50% off your order today',
          actualOffer: false,
        },
        {
          title: '60% off',
          description: '60% off your order today',
          actualOffer: false,
        },
        {
          title: '70% off',
          description: '70% off your order today',
          actualOffer: false,
        },
        {
          title: '80% off',
          description: '80% off your order today',
          actualOffer: false,
        },
        {
          title: '90% off',
          description: '90% off your order today',
          actualOffer: false,
        },
        {
          title: '100% off',
          description: '100% off your order today',
          actualOffer: false,
        },
      ],
      pointerAngle: 90,
      closeAfterSpinTime: 6,
    }

    if ( spinnerData ) {
      this.title = spinnerData?.title;
      this.description = spinnerData?.description;
      this.spinButtonText = spinnerData?.spinButtonText;
      this.spinnedTitle = spinnerData?.spinnedTitle;
      this.spinnedDescription = spinnerData?.spinnedDescription;
      this.spinnedButtonText = spinnerData?.spinnedButtonText;
      this.declineButtonText = spinnerData?.declineButtonText;
      this.wheelCenterImage = spinnerData?.wheelCenterImage;
      this.wheelOptions = spinnerData?.wheelOptions || [];
      this.pointerAngle = spinnerData?.pointerAngle || 90;
      this.closeAfterSpinTime = spinnerData?.closeAfterSpinTime || 6;


      this.rotationStartDegrees = (
        Math.floor( Math.random() * spinnerData.wheelOptions.length ) * ( 360 / spinnerData.wheelOptions.length )
      ).toString() || '120';

      const rightAnswerIndex = spinnerData.wheelOptions.findIndex( option => {
        return option.actualOffer === true;
      } );

      const offerAngle = (
        3600 // 10 spins
        + ( ( 360 / spinnerData.wheelOptions.length ) / 2 ) // Add half the angle of one option.
        - this.pointerAngle // Subtract the angle at which the pointer is at.
        + ( rightAnswerIndex * ( 360 / spinnerData.wheelOptions.length ) ) // Increament the total by the full angle for the number of options
      ).toString();

      this.rotationDegrees = offerAngle || '3495';
      // console.log( 'spinner - right answer Index: ', rightAnswerIndex );
      // console.log( 'spinner - rotationDegrees: ', this.rotationDegrees, ( this.rotationDegrees - 3600 ) );

    }

    this.spinnerKeyFrames = `
      .main-page-element {
        filter: blur(10px);
        filter:progid:DXImageTransform.Microsoft.Blur(pixelradius='10', shadowopacity='0.0');
      }

      upsell-offer-spinner .spinnerWheelMotor {
        transform: rotate(${ this.rotationStartDegrees }deg);
      }

      upsell-offer-spinner .spinnerWheelMotor.spinned {
        transform: rotate(${ this.rotationDegrees }deg)
      }

      upsell-offer-spinner .spinnerWheelMotor.spinning {
        transform: rotate(${ this.rotationDegrees }deg)
      }

      @keyframes spinTheWheel {
        0% {
          transform: rotate(${ this.rotationStartDegrees }deg)
        }
        100% {
          transform: rotate(${ this.rotationDegrees }deg)
        }
      }
    `;
    this.spinnerKeyFrames = `
      .main-page-element {
        filter: blur(10px);
        filter:progid:DXImageTransform.Microsoft.Blur(pixelradius='10', shadowopacity='0.0');
      }

      .spinnerWheelMotor {
        transform: rotate(${ this.rotationStartDegrees }deg);
      }

      .spinnerWheelMotor.spinned {
        transform: rotate(${ this.rotationDegrees }deg)
      }

      .spinnerWheelMotor.spinning {
        transform: rotate(${ this.rotationDegrees }deg)
      }

      @keyframes spinTheWheel {
        0% {
          transform: rotate(${ this.rotationStartDegrees }deg)
        }
        100% {
          transform: rotate(${ this.rotationDegrees }deg)
        }
      }
    `;

    const body = document.getElementsByTagName( 'body' )[ 0 ];
    const style = document.createElement( 'style' );
    style.type = 'text/css';
    style.setAttribute( 'id', 'spinner-specific-styles' );
    style.appendChild( document.createTextNode( this.spinnerKeyFrames ) );
    body.appendChild( style );
  }

  removeSpinnerStyles() {
    const body = document.getElementsByTagName( 'body' )[ 0 ];
    const style = document.getElementById( 'spinner-specific-styles' );
    console.log( 'spinner - styles', style );
    if ( style ) { body.removeChild( style ); }
  }


}

interface IPageContentBase {
  name: string;
  type: string;
  componentName?: any;
  html?: any;
  options?: any;
  class?: string;
}

interface IComponentPageContent extends IPageContentBase {
  componentName: string;
  options: any;
}

interface IHTMLPageContent extends IPageContentBase {
  html: string;
}

type IPageContent = IComponentPageContent | IHTMLPageContent;
