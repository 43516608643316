import { UpsellBase } from '../upsell/upsell-base';
import { NavControllerExt } from 'src/app/extensions/nav-controller-extension'
//import { LoadingController, NavParams, ToastController } from 'ionic-angular';
import { LoadingController } from 'src/app/components/ionic-replacements/LoadingController';
import { NavParams } from 'src/app/components/ionic-replacements/NavParams';
import { ToastController } from 'src/app/components/ionic-replacements/ToastController';

import { TouchcrApiOrderProvider } from 'src/app/providers/touchcr-api-order/touchcr-api-order';
import { TouchcrApiGeneralProvider } from 'src/app/providers/touchcr-api-general/touchcr-api-general';
import { EventStreamerProvider } from 'src/app/providers/event-stream';
import { OrderDetailsProvider } from 'src/app/providers/order-details/order-details';
import { PaypalPaymentProvider } from 'src/app/providers/paypal/paypal';
import { Inject, Injectable, NgZone, OnDestroy } from '@angular/core';
import { TouchcrApiShippingProvider } from 'src/app/providers/TouchcrApiShippingProvider';
import { TouchcrApiRemoteServicesProvider } from 'src/app/providers/touchcr-api-remoteservices';
import { CalculateProvider } from 'src/app/providers/calculate/calculate';
import { UrlsProvider } from 'src/app/providers/urls';
import { StateProvider } from 'src/app/providers/state';
import { TouchcrApiOrderformProvider } from 'src/app/providers/touchcr-api-orderform/touchcr-api-orderform';
import { FunnelSettingsProvider } from 'src/app/providers/funnel-settings';
import { GeneralProvider } from 'src/app/providers/general';
import { SpinnerProvider } from 'src/app/providers/spinner/spinner';

const BRAND = (window as any)['process_env'].BRAND;

@Injectable({ providedIn: 'root' })
export class UpsellFunnelSettingsBase extends UpsellBase implements OnDestroy {
  constructor(
    public override navCtrl: NavControllerExt,
    public override navParams: NavParams,
    public override tcrApi: TouchcrApiGeneralProvider,
    public override tcrApiOrder: TouchcrApiOrderProvider,
    public override loadingCtrl: LoadingController,
    public override eventStreamer: EventStreamerProvider,
    public override orderDetails: OrderDetailsProvider,
    public override paypalPayment: PaypalPaymentProvider,
    public override toastCtrl: ToastController,
    public override ngZone: NgZone,
    public override shippingProvider: TouchcrApiShippingProvider,
    public override tcrApiRemoteServices: TouchcrApiRemoteServicesProvider,
    public override calculateHelper: CalculateProvider,
    public override urlsHelper: UrlsProvider,
    public override stateProvider: StateProvider,
    public override tcrApiOrderform: TouchcrApiOrderformProvider,
    public funnelProvider: FunnelSettingsProvider,
    public override generalProvider: GeneralProvider,
    public override spinner: SpinnerProvider,
  ) {
    super(
      navCtrl,
      navParams,
      tcrApi,
      tcrApiOrder,
      loadingCtrl,
      eventStreamer,
      orderDetails,
      paypalPayment,
      toastCtrl,
      ngZone,
     
      shippingProvider,
      tcrApiRemoteServices,
      calculateHelper,
      urlsHelper,
      stateProvider,
      tcrApiOrderform,
      generalProvider,
      spinner,
    );
  }
  ngOnDestroy(): void {
    this.spinner.disable();
  }

  similarity(s1:any, s2:any) {
    var longer = s1;
    var shorter = s2;
    if (s1.length < s2.length) {
      longer = s2;
      shorter = s1;
    }
    var longerLength = longer.length;
    if (longerLength === 0) {
      return 1.0;
    }
    return (longerLength - this.editDistance(longer, shorter)) / parseFloat(longerLength);
  }


  editDistance(s1:any, s2:any) {
    s1 = s1.toLowerCase();
    s2 = s2.toLowerCase();

    var costs = new Array();
    for (var i = 0; i <= s1.length; i++) {
      var lastValue = i;
      for (var j = 0; j <= s2.length; j++) {
        if (i == 0)
          costs[j] = j;
        else {
          if (j > 0) {
            var newValue = costs[j - 1];
            if (s1.charAt(i - 1) != s2.charAt(j - 1))
              newValue = Math.min(Math.min(newValue, lastValue),
                costs[j]) + 1;
            costs[j - 1] = lastValue;
            lastValue = newValue;
          }
        }
      }
      if (i > 0)
        costs[s2.length] = lastValue;
    }
    return costs[s2.length];
  }

  getAlternativeOffer(brokenactual:any) {
    let matchto = brokenactual.substring(0,3);

    console.log("getAlternativeOffer: " + brokenactual);
    console.log("matchto: " + matchto);
    let foundp = false;
    let winner:any = '';
    let winnerPerc =0;

    if (this.navCtrl.getConfig()) {

      for (let prod = 0; prod < this.navCtrl.getConfig().length; prod++) {
        
        if (this.navCtrl.getConfig()[prod].path?.startsWith(matchto)) {
          let perc = Math.round(this.similarity(brokenactual, this.navCtrl.getConfig()[prod].path) * 10000) / 100;
          if(perc > winnerPerc) {
            winnerPerc = perc;
            winner = this.navCtrl.getConfig()[prod].path;
          }
          foundp = true;
          console.log("found p: " + brokenactual  + " and " + this.navCtrl.getConfig()[prod].path + " are similar " + perc + "%");
        }
      }
    }
    if (!foundp) {
      return 'Gundry_Receipt_AP';
    }
    return winner;   
  }

  showNext(skip:any) {	
    this.dataForTax = this.getTaxRateForUpsells({	
      detailsInfo: this.orderDetailsInfo,	
      upsellIndex: this.upsellIndex,	
    });	
    this.tcrApiRemoteServices	
      .getTaxRateForUpsell(this.dataForTax, this.state)	
      .toPromise()	
      .then((taxRate:any) => {	
        this.taxRate = taxRate;	
        if (taxRate === 'error') {	
          this.isErrorTax = true;	
        }	
        this.calculateTransactionFee();	
      });	
    return;	
  }
  override async noThanks() {
    let stp = this.urlsHelper.getParameterFromUrl({url: document.location.search, parameter: 'step'})
    this.spinner.enable('Loading page <br> Please wait...');
    for (let index = 0; index < this.buttonList.length; index++){
      this.buttonList[index].isPlaceOrder = true;
    }
    const params: any = await this.funnelProvider.getNextPage(undefined, false, this.upsell.type);
    if (!params) {
      setTimeout(() => {
        this.spinner.disable();
      },1000);

      if( typeof (window as any)['funnelConfsForUUID'] == 'object') {
        // handle the configuration error
        let brokenfunnel = (window as any)['funnelConfsForUUID'];
        let brokenfunnel_firstUpsell = brokenfunnel.defaultFunnels[stp].variants[0].url;
        let alt = this.getAlternativeOffer(brokenfunnel_firstUpsell);
        if(alt) {
          console.log("redirecting to alternative offer: " + alt);
          //return this.navCtrl.setRootExt(alt, { name: alt},{currentStep: stp, nextStep: (stp+1) });
          //return this.navCtrl.setRootExt(alt, { name: alt});
          return this.navCtrl.setRootExt(alt, { name: alt, isUpsell: true }, { taken: false, alternate: true})
        }
      }

      return this.toastCtrl
        .create({
          message: `Page not found`,
          position: 'top',
          showCloseButton: true,
          cssClass: 'errorToast',
        })
    }
    setTimeout(() => {
      this.spinner.disable();
    }, 1000);
    const { component, orderForm, type } = params;
    if (type === 'Upsell' || type === 'DownSell') {
      await this.stateProvider.setUpsells([orderForm]);
    }
    this.navCtrl.setRootExt(component.name, { name: component.name, isUpsell: true }, { taken: false})
      .then(() => {
        this.spinner.disable();
      })
      .catch((error) => {
        console.log('Error on noThanks()', error);
        this.spinner.disable();
      });
    return;
  }


  createTransactionBraintree(upsell:any, response:any, captureId:any) {
    console.log('[GH] createTransactionBraintree upsell', upsell);
    console.log('[GH] createTransactionBraintree response', response);
    console.log('[GH] createTransactionBraintree orderId', upsell.id);

    return new Promise((resolve, reject) => {
      let braintreeinterval = setInterval(async () => {
        clearInterval(braintreeinterval);
        this.tcrApiOrder.createTransaction({
          orderId: upsell.id, status: response.success ? 'Approved' : 'Failed',
          method: 'Braintree ' + response.transaction.currencyIsoCode, message: response.transaction.creditCard.maskedNumber.replace('null******null', 'PayPal') +
            ' token ' + response.transaction.creditCard.token + ' '
            + response.transaction.status + ' ' + response.transaction.currencyIsoCode + ' currencyConversion ' + this.braintreeCurrencyConversion +
            ' ' + this.currencySymbol + (response.transaction.amount),
          original: JSON.stringify(response.transaction)
        })
          .subscribe((result:any) => {
            if (result.message === 'The transaction has been created') {
              resolve({ message: 'success' });
            } else {
              reject('Transaction was not created!');
            }
          }, (error:any) => {
            reject(error);
          });
      }, 300);
    });
  }


  async processBraintreeResult(result:any) { // TOUC-14710 - Braintree
    console.log('[GH] processBraintreeResult result', result);
    this.spinner.enable('Please wait...');
    if (document.location.search.indexOf('braintreeforceupselldeclined=true') > -1) {
      console.log('[GH] braintreeforceupselldeclined=true');
      result.result.success = false;
    }

    if (result.result.success) {
      await this.createTransactionBraintree(result.upsell, result.result, 'Approved');
      setTimeout(async () => {
      await this.stateProvider.setPurchasedUpsells(result.upsell);

      if (this.paymentMethod.standard && this.transactionFeeAmount > 0) {
        this.captureTransactionFee(result.orderId);
        this.orderSTxId = '';
      }
      this.purchasedUpsells.push(result.upsell.order);
      const params: any = await this.funnelProvider.getNextPage(undefined, true);
      if (!params) {
        this.spinner.disable();
        return this.toastCtrl
          .create({
            message: `Page not found`,
            position: 'top',
            showCloseButton: true,
            cssClass: 'errorToast',
          })
          //.present();
      }
      const { component, orderForm, type } = params;
      if (type === 'Upsell' || type === 'Downsell') {
        await this.stateProvider.setUpsells([orderForm]);
      }
      this.navCtrl.setRootExt(component.name, { name: component.name, isUpsell: true }, { taken: true})
        .then(() => {
          this.spinner.disable();
        })
        .catch((error) => {
          this.toastCtrl.create({
            message: `Couldn't navigate to the next page`,
            position: 'top',
            showCloseButton: true,
            cssClass: 'errorToast',
          });
          console.log('Error on upgradeOrder()', error);
          this.spinner.disable();
        });
      }, 1000);
      
    } else {
      this.toastCtrl
      .create({
        message: `Error with upgrading order!  Please contact customer support.`,
        position: 'top',
      })
      //.present();
      this.createTransactionBraintree(result.upsell, result.result, 'Failed');
      const params: any = await this.funnelProvider.getNextPage(undefined, undefined, undefined, true);
      if (!params) {
        this.spinner.disable();
        return this.toastCtrl
          .create({
            message: `Page not found`,
            position: 'top',
            showCloseButton: true,
            cssClass: 'errorToast',
          })
          //.present();
      }
      const { component } = params;
      this.navCtrl.setRootExt(component.name, { name: component.name, isUpsell: true }, { taken: true})
        .then(() => {
          this.spinner.disable();
        })
        .catch((error) => {
          this.toastCtrl.create({
            message: `Couldn't navigate to the next page`,
            position: 'top',
            showCloseButton: true,
            cssClass: 'errorToast',
          });
          console.log('Error on upgradeOrder()', error);
          this.spinner.disable();
        });
    }
 
  }



  upgradeOrder() {
    this.spinner.enable('Updating order.<br>Please Wait');
    let upsells = [{ sfid: this.upsell.sfid, count: 1 }];
    let dataForTax = this.tcrApiRemoteServices.getDataForTaxUpgradeOrder(
      this.dataForTax,
      this.upsell,
    );
    let totalTax = this.taxRate.totalTax || 0;

    if (this.paymentMethod.paypal) {
      this.upgradePayPalOrder();
    } else if (this.paymentMethod.braintreeMethod) {
      this.upgradeBraintreeOrder(sessionStorage.getItem('deviceData'));  // TOUC-14710  -  Braintree GBP
    } else {
      this.order.transactionFeeAmount = this.transactionFeeAmount;
      this.order.transactionFeeDocumentId = this.orderSTxId;
      this.tcrApiOrder
        .payUpsells(this.order, upsells, this.upsell.type, totalTax, dataForTax)
        .toPromise()
        .then(
          async (result:any) => {
            let stp = this.urlsHelper.getParameterFromUrl({url: document.location.search, parameter: 'step'})
            this.spinner.enable('Please wait...');
            if (result.message === 'success') {
              if (this.paymentMethod.standard && this.transactionFeeAmount > 0) {
                this.captureTransactionFee(result.orderId);
                this.orderSTxId = '';
              }
              this.purchasedUpsells.push(result.order);
              await this.stateProvider.setPurchasedUpsells(result.order);
              const params: any = await this.funnelProvider.getNextPage(undefined, true);
              if (!params) {
                this.spinner.disable();

                if( typeof (window as any)['funnelConfsForUUID'] == 'object') {
                  // handle the configuration error
                  let brokenfunnel = (window as any)['funnelConfsForUUID'];
                  let brokenfunnel_firstUpsell = brokenfunnel.defaultFunnels[stp].variants[0].url;
                  let alt = this.getAlternativeOffer(brokenfunnel_firstUpsell);
                  if(alt) {
                    console.log("redirecting to alternative offer: " + alt);
                    //return this.navCtrl.setRootExt(alt, { name: alt},{currentStep: stp, nextStep: (stp+1) });
                    //return this.navCtrl.setRootExt(alt, { name: alt});
                    return this.navCtrl.setRootExt(alt, { name: alt, isUpsell: true }, { taken: true, alternate: true})

                  }
                }
                
                return this.toastCtrl
                  .create({
                    message: `Page not found`,
                    position: 'top',
                    showCloseButton: true,
                    cssClass: 'errorToast',
                  })
                 // .present();
              }
              const { component, orderForm, type } = params;
              if (type === 'Upsell' || type === 'Downsell') {
                await this.stateProvider.setUpsells([orderForm]);
              }
              this.navCtrl.setRootExt(component.name, { name: component.name, isUpsell: true }, { taken: true})
                .then(() => {
                  this.spinner.disable();
                })
                .catch((error) => {
                  this.toastCtrl.create({
                    message: `Couldn't navigate to the next page`,
                    position: 'top',
                    showCloseButton: true,
                    cssClass: 'errorToast',
                  });
                  console.log('Error on upgradeOrder()', error);
                  this.spinner.disable();
                });
            } else {
              const params: any = await this.funnelProvider.getNextPage(undefined, undefined, undefined, true);
              if (!params) {
                this.spinner.disable();
                return this.toastCtrl
                  .create({
                    message: `Page not found`,
                    position: 'top',
                    showCloseButton: true,
                    cssClass: 'errorToast',
                  })
                 // .present();
              }
              const { component } = params;
              this.navCtrl.setRootExt(component.name, { name: component.name, isUpsell: true }, { taken: true})
                .then(() => {
                  this.spinner.disable();
                })
                .catch((error) => {
                  this.toastCtrl.create({
                    message: `Couldn't navigate to the next page`,
                    position: 'top',
                    showCloseButton: true,
                    cssClass: 'errorToast',
                  });
                  console.log('Error on upgradeOrder()', error);
                  this.spinner.disable();
                });
            }
            return;
          },
          async (error:any) => {
            console.log('Error on upsell purchase!', error);
            this.spinner.disable();
            const params: any = await this.funnelProvider.getNextPage(undefined, undefined, undefined, true);
            if (!params) {
              this.spinner.disable();
              return this.toastCtrl
                .create({
                  message: `Page not found`,
                  position: 'top',
                  showCloseButton: true,
                  cssClass: 'errorToast',
                })
               // .present();
            }
            const { component } = params;
            this.navCtrl.setRootExt(component.name, { name: component.name, isUpsell: true }, { taken: true})
              .then(() => {
                this.spinner.disable();
              })
              .catch((error) => {
                this.toastCtrl.create({
                  message: `Couldn't navigate to the next page`,
                  position: 'top',
                  showCloseButton: true,
                  cssClass: 'errorToast',
                });
                console.log('Error on upgradeOrder()', error);
                this.spinner.disable();
              });
          },
        );
    }
  }

  upgradePayPalOrder() {
    this.spinner.enable('Executing order.<br> Please Wait');
    let dataForTax = this.tcrApiRemoteServices.getDataForTaxUpgradeOrder(
      this.dataForTax,
      this.upsell,
    );
    let paymentData = {
      totalPrice: +(this.upsell.offerPrice + this.upsell.shippingCost).toFixed(2),
      accDetails: this.orderDetailsInfo.navParams.accDetails,
      orderType: this.upsell.type,
      OffersData: [],
      bumpOffers: [],
      parentOrder: this.orderDetailsInfo.navParams.order,
      totalTax: this.taxRate.totalTax || 0,
      dataForTax: dataForTax,
      countries: this.countries
    };
    (paymentData.OffersData as any).push(this.upsell);
    this.paypalPayment.createPaymentUpsell(paymentData).then(
      (result: any) => {
        this.purchasedUpsells.push(result.order);
        this.stateProvider
          .setOrderDetails({
            order: this.orderDetailsInfo.navParams.order,
            upsells: this.orderDetailsInfo.navParams.upsells,
            purchasedUpsells: this.purchasedUpsells,
            upsellIndex: this.upsellIndex,
            paymentMethod: 'PayPal',
          })
          .then((state) => {
            this.state = state;
            this.plannedLeavePage = true;
            console.log('[GH] saving PayPalNVPParameters',document.location.search)
            sessionStorage.setItem('PayPalNVPParameters', document.location.search);
            window.open(result.url, '_self', 'location=no');
          });
      },
      () => {
        this.toastCtrl
          .create({
            message: `Error with upgrading paypal order!`,
            duration: 3000,
            position: 'top',
          })
          //.present();
        this.spinner.disable();
      },
    );
  }

  braintreeClientToken: string;


  upgradeBraintreeOrder(deviceData:any) {
    this.spinner.enable('Executing order.<br> Please Wait');
    let dataForTax = this.tcrApiRemoteServices.getDataForTaxUpgradeOrder(
      this.dataForTax,
      this.upsell,
    );
    let paymentData = {
      totalPrice: +(this.upsell.offerPrice + this.upsell.shippingCost).toFixed(2),
      accDetails: this.orderDetailsInfo.navParams.accDetails,
      orderType: this.upsell.type,
      OffersData: [],
      bumpOffers: [],
      parentOrder: this.orderDetailsInfo.navParams.order,
      totalTax: this.taxRate.totalTax || 0,
      dataForTax: dataForTax,
      countries: this.countries,
      paymentMethod: this.orderDetailsInfo.navParams.paymentMethod,     
      namedAgent: 'Braintree'
    };
    (paymentData.OffersData as any).push(this.upsell);
    // TODO - this creates the order by not a payment profile yet for guest user MVP version
    this.paypalPayment.createOrderBraintreeUpsell(paymentData).then(
      (result: any) => {
        console.log('before order', this.order);
        console.log('before this.upsell', this.upsell);
        console.log('before result', result);
        this.purchasedUpsells.push(result.order);
        this.stateProvider
          .setOrderDetails({
            order: this.orderDetailsInfo.navParams.order,
            upsells: this.orderDetailsInfo.navParams.upsells,
            purchasedUpsells: this.purchasedUpsells,
            upsellIndex: this.upsellIndex,
            paymentMethod: this.orderDetailsInfo.navParams.paymentMethod
          })
          .then((state) => {
            this.state = state;
            let braintreeClientToken = sessionStorage.getItem('braintree-payment-token');
            console.log('after order', this.order);
            console.log('after this.upsell', this.upsell);
            console.log('after result', result);
            const countryISO = this.calculateHelper.getCountryISOCode(this.countries, this.order.billCountry);
            let braintreeCurrencyConversion = this.order.crv || '1';
            let description = result.order.name + ' currencyConversion ' + this.braintreeCurrencyConversion + ' Total: $' + (result.order.total) + '  Tax: $' + result.order.tax; 
            let body = {
              "orderTotal": +(result.order.total * parseFloat(braintreeCurrencyConversion)).toFixed(2),
              "totalPrice": +(result.order.total * parseFloat(braintreeCurrencyConversion)).toFixed(2),
              "totalTax": +(result.order.tax * parseFloat(braintreeCurrencyConversion)).toFixed(2),
              "braintreeCurrencyConversion": braintreeCurrencyConversion,
              "submitForSettlement": true,
              "accDetails": {
                "email": this.order.customerEmail,
                "billingAddress": {
                  "country": this.order.billingCountry,
                  "state": this.order.billingState,
                  "city": this.order.billingCity,
                  "street": this.order.billingAddress,
                  "postal": this.order.billingZip,
                  "st": this.order.billingState
                },
              },
              "paymentMethodToken": braintreeClientToken,
              "billingCountryAlpha2": countryISO,
              "amount": +(result.order.total * parseFloat(braintreeCurrencyConversion)).toFixed(2),
              "currency": sessionStorage.getItem('officialCurrency'),
              "deviceData": deviceData,
              "description": description
            }
            let xhr = new XMLHttpRequest();
            let asy = false;
            xhr.open('POST', '/checkout-braintree/', asy);
            xhr.withCredentials = true;
            if (typeof (window as any)['getCookie'] === 'function') xhr.setRequestHeader('XSRF-TOKEN', (window as any)['getCookie']('XSRF-TOKEN')); // [TOUC-5682]
            xhr.onload = (e) => {
              if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                  console.log('[GH] braintree response', JSON.parse(xhr.responseText));
                  let ret = JSON.parse(xhr.responseText);
                  ret['order'] = this.order;
                  ret['upsell'] = result.order;
                  this.processBraintreeResult(ret);
                  return xhr.responseText;
                } else {
                  console.error('[GH] Braintree error ', xhr.statusText);
                  this.spinner.disable();
                  this.toastCtrl
                    .create({
                      message: `Error with upgrading braintree order.`,
                      position: 'top',
                      showCloseButton: true,
                      cssClass: 'errorToast',
                    })
                    //.present();
                  this.navCtrl.setRootExt('OrderSuccessCommonPage', {
                    name: 'Order Success', isUpsell: true
                  });
                  return { error: true, message: xhr.statusText };
                }
              }
              return;
            };
            xhr.onerror = (e) => {
              console.error(xhr.statusText);
            };

            (xhr as any)['dataType'] = 'json';
            xhr.setRequestHeader('X-Brand', (window as any)['process_env'].BRAND);
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
            console.log('[GH] braintree request body', body);
            xhr.send(JSON.stringify(body));
          

          });
      },
      () => {
        this.toastCtrl
          .create({
            message: `Error with upgrading braintree paypal order!`,
            duration: 3000,
            position: 'top',
          })
         // .present();
        this.spinner.disable();
      },
    );
  }

  executePayPalPayment(paramData:any) {
    this.spinner.enable('Executing order.<br> Please Wait');
    paramData['isUpsell'] = true;
    paramData.dataForTax = this.state.taxData;
    this.paypalPayment.executePayment(paramData)
      .then(async (res: any) => {
        this.spinner.enable('Loading page <br> Please wait...');
        if (res.message === 'success') {
          await this.stateProvider.setPurchasedUpsells(res.orderDetails.purchasedUpsells[res.orderDetails.purchasedUpsells.length - 1]);
          const params: any = await this.funnelProvider.getNextPage(undefined, true);
          if (!params) {
            this.spinner.disable();
            return this.toastCtrl
              .create({
                message: `Page not found`,
                position: 'top',
                showCloseButton: true,
                cssClass: 'errorToast',
              })
              //.present();
          }
          const { component, orderForm, type } = params;
          if (type === 'Upsell' || type === 'Downsell') {
            await this.stateProvider.setUpsells([orderForm]);
          }
          this.navCtrl.setRootExt(component.name, { name: component.name, isUpsell: true }, { taken: true})
            .then(() => {
              this.spinner.disable();
            })
            .catch((error) => {
              this.toastCtrl.create({
                message: `Couldn't navigate to the next page`,
                position: 'top',
                showCloseButton: true,
                cssClass: 'errorToast',
              });
              console.log('Error on executePayPalPayment()', error);
              this.spinner.disable();
            });
        } else {
          const params: any = await this.funnelProvider.getNextPage(undefined, undefined, undefined, true);
          if (!params) {
            this.spinner.disable();
            return this.toastCtrl
              .create({
                message: `Page not found`,
                position: 'top',
                showCloseButton: true,
                cssClass: 'errorToast',
              })
            //  .present();
          }
          const { component } = params;
          this.navCtrl.setRootExt(component.name, { name: component.name, isUpsell: true }, { taken: true})
            .then(() => {
              this.spinner.disable();
            })
            .catch((error) => {
              this.toastCtrl.create({
                message: `Couldn't navigate to the next page`,
                position: 'top',
                showCloseButton: true,
                cssClass: 'errorToast',
              });
              console.log('Error on executePayPalPayment()', error);
              this.spinner.disable();
            });
        }
      },
        async (error) => {
          console.log('Error on upsell purchase!', error);
          this.spinner.enable('Loading page <br> Please wait...');
          const params = await this.funnelProvider.getNextPage(undefined, undefined, undefined, true);
          if (!params) {
            this.spinner.disable();
            return this.toastCtrl
              .create({
                message: `Page not found`,
                position: 'top',
                showCloseButton: true,
                cssClass: 'errorToast',
              })
            //  .present();
          }
          const { component } = params;
          this.navCtrl.setRootExt(component.name, { name: component.name, isUpsell: true }, { taken: true})
            .then(() => {
              this.spinner.disable();
            })
            .catch((error) => {
              this.toastCtrl.create({
                message: `Couldn't navigate to the next page`,
                position: 'top',
                showCloseButton: true,
                cssClass: 'errorToast',
              });
              console.log('Error on executePayPalPayment()', error);
              this.spinner.disable();
            });
        },
      );
  }

  executePayPalNVP(urlParameters:any, upsellOrder:any) {
    this.urlsHelper.clearPayPalParams();
    this.spinner.enable('Executing order.<br> Please Wait');
    const upsellOrderDetails = {
      order: upsellOrder,
      isUpsell: true};
    const shippingFullCountry = upsellOrderDetails.order.shippingCountry;
    const countryISO = this.calculateHelper.getCountryISOCode(this.countries, shippingFullCountry);
    const stateISO = this.countries && this.countries[shippingFullCountry] && this.countries[shippingFullCountry].states ?
      Object.keys(this.countries[shippingFullCountry].states).find(e => this.countries[shippingFullCountry].states[e] === upsellOrderDetails.order.shippingState)
      : upsellOrderDetails.order.shippingState;
    let executeBody = {
      order: upsellOrderDetails,
      paymentBody: {
        TOKEN: urlParameters.token,
        PAYERID: urlParameters.PayerID,
        PAYMENTREQUEST_0_AMT: +upsellOrderDetails.order.total.toFixed(2),
        PAYMENTREQUEST_0_CURRENCYCODE: 'USD',
        PAYMENTREQUEST_0_SHIPTONAME: upsellOrderDetails.order.customerName,
        PAYMENTREQUEST_0_SHIPTOSTREET: upsellOrderDetails.order.shippingAddress,
        PAYMENTREQUEST_0_SHIPTOCITY: upsellOrderDetails.order.shippingCity,
        PAYMENTREQUEST_0_SHIPTOSTATE: stateISO,
        PAYMENTREQUEST_0_SHIPTOZIP: upsellOrderDetails.order.shippingZipPostal,
        PAYMENTREQUEST_0_SHIPTOCOUNTRYCODE: countryISO,
        L_PAYMENTREQUEST_0_AMT0: +upsellOrderDetails.order.orderItems[0].totalPrice,
        L_PAYMENTREQUEST_0_NAME0: upsellOrderDetails.order.orderItems[0].productName,
        L_PAYMENTREQUEST_0_QTY0: upsellOrderDetails.order.orderItems[0].quantity,
        PAYMENTREQUEST_0_ITEMAMT: +upsellOrderDetails.order.orderItems[0].productPrice.toFixed(2),
        PAYMENTREQUEST_0_SHIPPINGAMT: +upsellOrderDetails.order.orderShippingCost.toFixed(2),
        PAYMENTREQUEST_0_TAXAMT: +upsellOrderDetails.order.tax.toFixed(2),
      }
    };
    if (this.taxServiceEnabled) {
      (executeBody as any)['dataForTax'] = this.state.taxData;
    }

    this.tcrApiOrder.doExpressCheckout(executeBody)
      .subscribe(async (response:any) => {
        this.spinner.enable('Loading page <br> Please wait...');
        await this.stateProvider.setPurchasedUpsells(response.order);
        const params: any = await this.funnelProvider.getNextPage(undefined, true);
        if (!params) {
          this.spinner.disable();
          return this.toastCtrl
            .create({
              message: `Page not found`,
              position: 'top',
              showCloseButton: true,
              cssClass: 'errorToast',
            })
           // .present();
        }
        const { component, orderForm, type } = params;
        if (type === 'Upsell' || type === 'Downsell') {
          await this.stateProvider.setUpsells([orderForm]);
        }
        this.navCtrl.setRootExt(component.name, { name: component.name, isUpsell: true }, { taken: true})
          .then(() => {
            this.spinner.disable();
          })
          .catch((error) => {
            this.toastCtrl.create({
              message: `Couldn't navigate to the next page`,
              position: 'top',
              showCloseButton: true,
              cssClass: 'errorToast',
            });
            console.log('Error on executePayPalNVP()', error);
            this.spinner.disable();
          });
      }, async (error) => {
        this.spinner.disable();
        const params = await this.funnelProvider.getNextPage(undefined, undefined, undefined, true);
        if (!params) {
          this.spinner.disable();
          return this.toastCtrl
            .create({
              message: `Page not found`,
              position: 'top',
              showCloseButton: true,
              cssClass: 'errorToast',
            })
           // .present();
        }
        const { component } = params;
        this.navCtrl.setRootExt(component.name, { name: component.name, isUpsell: true }, { taken: true})
          .then(() => {
            this.spinner.disable();
          })
          .catch((error) => {
            this.toastCtrl.create({
              message: `Couldn't navigate to the next page`,
              position: 'top',
              showCloseButton: true,
              cssClass: 'errorToast',
            });
            console.log('Error on executePayPalNVP()', error);
            this.spinner.disable();
          });
      });
  }


  override getTaxRateForUpsells(data:any) {
    // generate data for get tax amount for upsels
    let dataForTax: any = {};
    let shipCountry = data.detailsInfo.order.shippingCountry;
    let shipState = data.detailsInfo.order.shippingState;

    if (data.upsellIndex < 0) {
      data.upsellIndex = 0;
    }

    if (this.countries && this.countries[shipCountry] && this.countries[shipCountry].states) {
      for (let state in this.countries[shipCountry].states) {
        this.statesList.push({ value: state, label: this.countries[shipCountry].states[state] });
      }
    }

    let to_state = '';
    for (let i = 0; i < this.statesList.length; i++) {
      if (this.statesList[i].label === shipState) {
        to_state = this.statesList[i].value;
      }
    }

    let accountDetails = {
      shipTo: {
        line1: data.detailsInfo.order.shippingAddress,
        city: data.detailsInfo.order.shippingCity,
        region: to_state || shipState,
        country: this.countries[shipCountry] ? this.countries[shipCountry].value : shipCountry,
        postalCode: data.detailsInfo.order.shippingZipPostal,
      },
    };

    dataForTax['accountDetails'] = accountDetails;
    let productDetails = [];
    let item = {};
    item = {
      number: data.detailsInfo.upsells[data.upsellIndex].sfid,
      quantity: 1,
      amount: data.detailsInfo.upsells[data.upsellIndex].offerPrice,
      taxCode: data.detailsInfo.upsells[data.upsellIndex].productTaxCode,
      description: data.detailsInfo.upsells[data.upsellIndex].name,
      productDescription: data.detailsInfo.upsells[data.upsellIndex].productDescription,
    };
    productDetails.push(item);
    dataForTax.productDetails = productDetails;
    dataForTax.shipping = data.detailsInfo.upsells[data.upsellIndex].shippingCost;
    dataForTax.total = data.detailsInfo.upsells[data.upsellIndex].offerPrice;
    return dataForTax;
  }

  payWithAmazon() {
    this.spinner.enable('Executing order.<br> Please Wait');
    let dataForTax = this.tcrApiRemoteServices.getDataForTaxUpgradeOrder(this.dataForTax, this.upsell);
    let upsells = [{ sfid: this.upsell.sfid, count: 1 }];
    let body:any = {
      accId: this.order.accountId,
      orderType: this.upsell.type,
      parent: this.order.id,
      orderSource: 'Funnel',
      customerIp: sessionStorage.getItem('ip'),
      upsells: upsells,
      orderTotal: +(this.upsell.offerPrice + this.upsell.shippingCost + (this.taxRate.totalTax || 0)).toFixed(2),
      totalTax: this.taxRate.totalTax || 0,
      dataForTax: dataForTax,
    };

    body['paymentMethod'] = 'Amazon Pay';
    body['namedAgent'] = 'Amazon Pay';
    body['brand'] = (window as any)["process_env"].BRAND;
    body['orderReferenceId'] = (<any>window).orderReferenceId;
    body['AccessToken'] = (<any>window).access_token;

    this.plannedLeavePage = true;

    this.tcrApiOrder.captureAmazon(body).toPromise()
      .then(
        async (result:any) => {
          for (let index = 0; index < this.buttonList.length; index++){
            this.buttonList[index].isPlaceOrder = true;
          }
          this.spinner.enable('Please wait...');

          this.purchasedUpsells.push(result.order);
          await this.stateProvider.setPurchasedUpsells(result.order);
          const params: any = await this.funnelProvider.getNextPage(undefined, true);
          if (!params) {
            this.spinner.disable();
            return this.toastCtrl
              .create({
                message: `Page not found`,
                position: 'top',
                showCloseButton: true,
                cssClass: 'errorToast',
              })
            //  .present();
          }
          const { component, orderForm, type } = params;
          if (type === 'Upsell' || type === 'Downsell') {
            await this.stateProvider.setUpsells([orderForm]);
          }
          this.navCtrl.setRootExt(component.name, { name: component.name, isUpsell: true }, { taken: true})
            .then(() => {
              this.spinner.disable();
            })
            .catch((error) => {
              this.toastCtrl.create({
                message: `Couldn't navigate to the next page`,
                position: 'top',
                showCloseButton: true,
                cssClass: 'errorToast',
              });
              console.log('Error on upgradeOrder()', error);
              this.spinner.disable();
            });
        },
        async (error) => {
          for (let index = 0; index < this.buttonList.length; index++){
            this.buttonList[index].isPlaceOrder = true;
          }
          console.log('Error on upsell purchase!', error);
          this.spinner.disable();
          const params: any = await this.funnelProvider.getNextPage(undefined, undefined, undefined, true);
          if (!params) {
            this.spinner.disable();
            return this.toastCtrl
              .create({
                message: `Page not found`,
                position: 'top',
                showCloseButton: true,
                cssClass: 'errorToast',
              })
             // .present();
          }
          const { component } = params;
          this.navCtrl.setRootExt(component.name, { name: component.name, isUpsell: true }, { taken: true})
            .then(() => {
              this.spinner.disable();
            })
            .catch((error) => {
              this.toastCtrl.create({
                message: `Couldn't navigate to the next page`,
                position: 'top',
                showCloseButton: true,
                cssClass: 'errorToast',
              });
              console.log('Error on upgradeOrder()', error);
              this.spinner.disable();
            });
        },
      );
  }

}
