<div class="scroll-content">
    <div class="container_terms">
      <h1 class="title">Terms and Conditions</h1>
      <p>
        These Terms and Conditions are the official terms of use for Gundry MD
        website at http://gundrymd.com. The content and services available on the
        Site is provided to you by Gundry MD (“http://gundrymd.com”, “us”, “our”,
        “we”) subject to the following terms and conditions. Please also review
        our Privacy Policy, and the other terms and policies you may find
        throughout our Site in connection with certain functionality, ordering
        information or promotions. By accessing or using the Site, you are
        acknowledging that you have read, understand, and agree to be bound by
        these Terms and Conditions, and the other guidelines, policies and terms
        posted on the Site.
      </p>
      <p>
        Gundry MD reserves the right to update or modify these Terms and
        Conditions at any time. When changes are made, we will make the revised
        version available on this webpage, and will update the “Last Updated”
        date, copied below. For this reason, we encourage you to review these
        Terms and Conditions whenever you purchase products from Gundry MD or
        otherwise use the Site. By using these Site, you agree to be bound by the
        Terms and Conditions posted on the Site at the time of your access.
      </p>
      <h4 class="sub-title">BINDING CONTRACT</h4>
      <p>
        These Terms and Conditions form a legally binding contract between you and
        us. By using our Site, you affirm that you are at least 18 years of age
        and are fully able and competent to enter into the terms, conditions,
        obligations, representations and responsibilities set forth in these Terms
        and Conditions, and to abide and comply with these Terms and Conditions.
      </p>
      <h4 class="sub-title">INTELLECTUAL PROPERTY</h4>
      <p>
        Everything on or used in connection with our Site, including but not
        limited to Gundry MD name and logo, product images and descriptions, Site
        design, the look and feel of the Site, text, graphics, button icons,
        images, audio clips, page headers, trademarks, content, the selection and
        arrangement thereof, and all software belongs to or is licensed to Gundry
        MD, and is protected by U.S. and international laws, including laws
        governing copyrights and trademarks. Permission is granted to
        electronically copy and to print in hard copy portions of the Site for
        purposes of placing an order with Gundry MD or for non-commercial uses.
        Any other use of the materials on the Site – including reproduction for
        purposes other than those permitted above, modification, distribution,
        republishing, transmission, display or performance – without the prior
        written permission of Gundry MD is strictly prohibited. Use of any
        materials on the site in connection with any product or service that is
        not offered by http://gundrymd.com in any manner that is likely to cause
        confusion among customers, or in any manner that disparages or discredits
        Gundry MD, is also prohibited.
      </p>
      <p>
        You may not use any data mining, robots, or similar data gathering and
        extraction tools on the Site or frame any portion of the site. You may not
        circumvent any mechanisms included in the Site for preventing the
        unauthorized reproduction or distribution of the content or materials
        contained on the Site.
      </p>
      <h4 class="sub-title">INACCURACY DISCLAIMER</h4>
      <p>
        From time to time there may be information on the Site that contains
        typographical errors, inaccuracies, or omissions that may relate to
        product descriptions, pricing, and availability. http://gundrymd.com
        reserves the right to correct any errors, inaccuracies or omissions and to
        change or update information at any time without prior notice (including
        after you have submitted your order).
      </p>
      <p>
        While we make reasonable efforts to ensure the information provided on the
        Site is accurate, we make no warranties about the accuracy and reliability
        of the information, data or content on the Site. The content on the Site
        is provided for informational purposes only. We shall not be responsible
        or liable for the accuracy, usefulness or availability of any information
        transmitted or made available via the Site. The information contained on
        the Site may be compiled from a variety of sources. See the “Disclaimer”
        Section below for more details.
      </p>
      <h4 class="sub-title">PROPER USE OF THE SITE</h4>
      <p>
        You agree that you will use the Site in compliance with all applicable
        local, state, national, and international laws, rules and regulations,
        including any laws regarding the transmission of technical data exported
        from your country of residence and all United States export control laws.
        Violation of any of the foregoing may result in immediate termination of
        these Terms and Conditions and may subject you to state and federal
        penalties and other legal consequences. We reserve the right, but shall
        have no obligation, to investigate your use of the site in order to (a)
        determine whether a violation of these Terms and Conditions has occurred
        or (b) comply with any applicable law, regulation, legal process or
        governmental request.
      </p>
      <p>
        You may not use the site in any manner that could damage, disable,
        overburden, or impair our servers or networks, or interfere with any other
        party’s use and enjoyment of the site. You may not attempt to gain
        unauthorized access to any services, user accounts, computer systems or
        networks, through hacking, password mining or any other means. We may take
        any legal and technical remedies to prevent the violation of this
        provision and to enforce these terms.
      </p>
      <p>
        Certain parts of the site may require a password to enter. You agree that
        you are responsible for all acts or omissions that occur on your account
        while your password is being used. If you believe someone has used your
        password or account without your authorization, you must notify us
        immediately. We reserve the right to access and disclose any information,
        including, without limitation, user names of accounts and other
        information, to comply with applicable laws and lawful government
        requests.
      </p>
      <h4 class="sub-title">THIRD PARTY</h4>
      <p>
        http://gundrymd.com may from time to time provide links to other website
        or applications (collectively, “Third Party Website”) as a service to
        those interested in this information. Links to Third Party Website may
        also be posted by third parties on http://gundrymd.com’s Blog and on
        http://gundrymd.com’s Applications. http://gundrymd.com does not monitor,
        approve or have any control over any content located on Third Party
        Website. The inclusion of any links to Third Party Website does not imply
        any association or relationship between http://gundrymd.com and the Third
        Party Website, and http://gundrymd.com does not guarantee, endorse or
        adopt the accuracy or completeness of content on any Third Party Website.
        http://gundrymd.com is not responsible for updating or reviewing content
        on Third Party Website. You use Third Party Website at your own risk.
        Links to Third Party Website do not imply legal authority to use any
        protected rights of others reflected in the links. If you use any of these
        links, you will leave the site.
      </p>
      <p>
        Certain functionality on the site may permit interactions that you
        initiate between the site and a third party web site or service (“social
        features”) if you choose to use social features, information you post or
        provide access to may be publicly displayed on the site or by the provider
        of the social feature that you use. Similarly, if you post information on
        a third party platform that references the site (e.g., by using a hashtag
        associated with http://gundrymd.com in a tweet or status update), your
        post may be published on our site in accordance with the terms of the
        third party web site or service. Also, both http://gundrymd.com and the
        third party may have access to certain information about you and your use
        of the site and the third party site or service. In addition, we may
        receive information about you if other users of a third party web site
        give us access to their profiles and you are one of their “connections,”
        or information about you is otherwise accessible through your
        “connection’s” web page, profile page, or similar page on a social
        networking or other third party web site or interactive service. The
        information we collect in connection with social features is subject to
        http://gundrymd.com’s Privacy Policy. The information collected and stored
        by the third party remains subject to the third party’s privacy practices,
        including whether the third party continues to share information with us,
        the types of information shared, and your choices with regard to what is
        visible to others on that third party web site or service.
      </p>
      <h4 class="sub-title">CHILDREN UNDER 13</h4>
      <p>
        The site are not intended for children. We do not knowingly collect
        personally identifiable information from children, and none of the site
        are designed to attract children. Children under 13 may look at the site,
        but are not permitted to make a purchase, create an account, enter a
        promotion, submit a customer survey, sign-up for emails or post comments
        on http://gundrymd.com’s Blog or on http://gundrymd.com’s Applications. If
        we discover someone under the age of 13 has created an account or has
        otherwise provided us with their personal information, no matter how
        adorable they might be, we will remove their personally identifiable
        registration information from our records. If you are under 13 and would
        like to make a purchase, please ask a parent or legal guardian to place
        the order for you.
      </p>
      <h4 class="sub-title">USE OF PRODUCTS AND SERVICES</h4>
      <p>
        The products and services available on the site, including any samples
        http://gundrymd.com may provide to you, are for personal use only. All
        material and information presented by http://gundrymd.com is intended to
        be used for personal educational or informational purposes only. The
        statements made about products have not been evaluated by the U.S. Food
        and Drug Administration and the results reported, if any, may not
        necessarily occur in all individuals. The statements and products are not
        intended to diagnose, treat, cure or prevent any condition or disease. All
        products should be used strictly in accordance with their instructions,
        precautions and guidelines. You should always check the ingredients for
        products to avoid potential allergic reactions.
      </p>
      <p>
        Use of the site is not meant to serve as a substitute for professional
        medical advice: these site are solely online stores for specialty beauty
        products. Please consult with your own physician or health care
        practitioner regarding the use of any goods, products or information
        received from the site before using or relying on them. Your physician or
        health care practitioner should address any and all medical questions,
        concerns and decisions regarding the possible treatment of any medical
        condition. http://gundrymd.com does not give or intend to give any answers
        to medical related questions and these site do not replace any medical
        professional or medical resource. http://gundrymd.com does not represent
        itself as a physician nor is this implied. No prescription medications or
        medical treatments are intentionally provided on the site.
      </p>
      <p>
        You may not sell or resell any of products or services you purchase or
        otherwise receive from http://gundrymd.com. http://gundrymd.com reserves
        the right, with or without notice, to cancel or reduce the quantity of any
        order to be filled or products or services to be provided to you where
        http://gundrymd.com, in its sole discretion, believes may result in the
        violation of these Terms and Conditions.
      </p>
      <p>
        California’s Proposition 65 entitles California consumers to special
        warnings for products that contain chemicals known to the state of
        California to cause cancer and birth defects or other reproductive harm if
        those products expose consumers to such chemicals above certain threshold
        levels. We care about our customers’ safety and hope that the information
        below helps with your buying decisions.
      </p>
      <p>
        We are providing the following warning for products linked to this page:
      </p>
      <p>
        WARNING: This product contains chemicals known to the State of California
        to cause cancer and birth defects or other reproductive harm.
      </p>
      <h4 class="sub-title">COLOR INFORMATION</h4>
      <p>
        While http://gundrymd.com has tried to accurately display the colors of
        products, the actual colors you see will depend on the device you are
        using to view the product and may not be accurate.
      </p>
      <h4 class="sub-title">REFUND POLICY</h4>
      <p>
        Gundry MD offers a 90-Day Money Back Guarantee on all of its products
        purchased directly through this Site. Guarantee shall commence on the date
        the order is shipped from our facilities. All return shipping charges,
        including without limitation, importing/exporting fees, are the
        responsibility of the customer. All returns must be shipped with a unique
        track-able number (a tracking number). Please contact our customer
        representative for return instructions.
      </p>
      <h4 class="sub-title">MOBILE SERVICES</h4>
      <p>
        If you access the site via your mobile phone, we do not currently charge
        for this access. Please be aware that your carrier’s normal rates and
        fees, such as text messaging fees or data charges, will still apply.
      </p>
      <h4 class="sub-title">DISCLAIMER</h4>
      <p>
        BENEFIT IS PROVIDING THE site AND THEIR CONTENTS ON AN “AS-IS” BASIS AND
        MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED,
        WITH RESPECT TO THE OPERATION OF THE site, THE INFORMATION, CONTENT,
        MATERIALS OR PRODUCTS, INCLUDED ON THE site. TO THE FULLEST EXTENT
        PERMITTED BY LAW, BENEFIT DISCLAIMS ALL SUCH REPRESENTATIONS AND
        WARRANTIES, INCLUDING FOR EXAMPLE WARRANTIES OF MERCHANTABILITY AND
        FITNESS FOR A PARTICULAR PURPOSE. IN ADDITION, BENEFIT DOES NOT REPRESENT
        OR WARRANT THAT THE INFORMATION ACCESSIBLE VIA THE site IS ACCURATE,
        COMPLETE OR CURRENT. BENEFIT WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY
        KIND ARISING OUT OF OR IN CONNECTION WITH THE USE OF THE site. THIS IS A
        COMPREHENSIVE LIMITATION OF LIABILITY THAT APPLIES TO ALL DAMAGES OF ANY
        KIND, INCLUDING BUT NOT LIMITED TO DIRECT, INDIRECT, INCIDENTAL, PUNITIVE
        OR CONSEQUENTIAL DAMAGES, LOSS OF DATA, INCOME OR PROFIT, LOSS OF OR
        DAMAGE TO PROPERTY AND CLAIMS OF THIRD PARTIES.
      </p>
      <h4 class="sub-title">LIMITATION OF LIABILITY</h4>
      <p>
        EXCEPT AS PROVIDED IN THE DISCLAIMER SECTION ABOVE, YOUR SOLE AND
        EXCLUSIVE REMEDY FOR ANY OTHER DISPUTE WITH US RELATING TO THE USE OF THE
        site IS TO DISCONTINUE YOUR USE OF THE site. IN NO EVENT SHALL OUR
        LIABILITY, OR THE LIABILITY OF OUR AGENTS, AFFILIATES, SUBSIDIARIES AND
        REPRESENTATIVES FOR ANY AND ALL CLAIMS RELATING TO THE USE OF THE site
        EXCEED THE TOTAL AMOUNT OF FEES THAT YOU PAID US DURING A ONE-YEAR PERIOD
        FOR THE SPECIFIC SERVICE AT ISSUE OR THE COST OF THE PRODUCT AT ISSUE.
        YOUR SOLE AND EXCLUSIVE REMEDY FOR ANY DISPUTE REGARDING ANY PRODUCT
        PURCHASED ON THE site IS A REFUND OF THE PRICE PAID OR REPLACEMENT OF THE
        PRODUCT, AS MORE SPECIFICALLY DESCRIBED IN THE PURCHASE TERMS.
      </p>
      <p>
        WE, OUR AGENTS, AFFILIATES, SUBSIDIARIES AND REPRESENTATIVES SHALL NOT BE
        LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR EXEMPLARY
        DAMAGES ARISING FROM YOUR USE OF, INABILITY TO USE, OR RELIANCE UPON THE
        site OR ANY PRODUCT ADVERTISED OR SOLD ON THE site. THESE EXCLUSIONS APPLY
        TO ANY CLAIMS FOR LOST PROFITS, LOST DATA, LOSS OF GOODWILL, INJURY, WORK
        STOPPAGE, COMPUTER FAILURE OR MALFUNCTION, OR ANY OTHER COMMERCIAL DAMAGES
        OR LOSSES, EVEN IF WE KNEW OR SHOULD HAVE KNOWN OF THE POSSIBILITY OF SUCH
        DAMAGES. BECAUSE SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION
        OR THE LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN
        SUCH STATES OR JURISDICTIONS, OUR LIABILITY, AND THE LIABILITY OF OUR
        SUBSIDIARIES AND AFFILIATES, SHALL BE LIMITED TO THE EXTENT PERMITTED BY
        LAW.
      </p>
      <p>
        WE DO NOT ENDORSE AND ARE NOT RESPONSIBLE FOR THE ACCURACY OR RELIABILITY
        OF ANY OPINION, INFORMATION, ADVICE OR STATEMENT ON THE site. UNDER NO
        CIRCUMSTANCES WILL WE BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY YOUR
        RELIANCE ON INFORMATION OBTAINED THROUGH EITHER THE CONTENT ON THE site OR
        USE OF ANY PRODUCT ADVERTISED OR SOLD ON THE site. IT IS YOUR
        RESPONSIBILITY TO EVALUATE THE ACCURACY, COMPLETENESS OR USEFULNESS OF ANY
        INFORMATION, OPINION, ADVICE OR OTHER CONTENT AVAILABLE THROUGH THE site.
        PLEASE SEEK THE ADVICE OF PROFESSIONALS, AS APPROPRIATE, REGARDING THE
        EVALUATION OF ANY SPECIFIC INFORMATION, OPINION, ADVICE OR OTHER CONTENT
        ON THE site.
      </p>
      <h4 class="sub-title">USER CONTENT</h4>
      <p>
        Users of our site have the opportunity to post certain content on our
        site, such as reviews and/or ratings. By using these you acknoledge that
        this is a binding part of the site’s Terms and Conditions. Therefore,
        please ensure you read the Privacy Policy carefully when viewing the site.
      </p>
      <h4 class="sub-title">COPYRIGHT POLICY</h4>
      <p>
        If you believe your work has been copied in a way that constitutes
        copyright infringement, please provide http://gundrymd.com’s designated
        copyright agent (listed below) with the following written information in
        accordance with the Digital Millennium Copyright Act (17 U.S.C. 512 et
        seq.): 1. A physical or electronic signature of the person authorized to
        act on behalf of the copyright owner; 2. A description of the copyrighted
        work claimed to have been infringed; 3. A description of the material that
        you claim is infringing, and where it is located on the site; 4. Your
        address, telephone number, and email address; 5. A statement by you that
        you in good faith believe that the disputed use is not authorized by the
        copyright owner, its agent, or the law; 6. A statement that you consent to
        the jurisdiction of the United States District Court for the judicial
        district pertaining to your address, or if your address is outside of the
        United States, for any judicial district in which http://gundrymd.com is
        located, and that you will accept service of process from the person who
        provided notification or an agent of such person; and 7. A statement by
        you, made under penalty of perjury, that the above information is
        accurate, and that you are authorized to act on behalf of the copyright
        owner.
      </p>
      <p>
        If you receive such a notice, you may provide counter-notification in
        writing to the designated agent that includes the information below. To be
        effective, the counter-notification must be a written communication that
        includes the following:
      </p>
      <p>1. Your physical or electronic signature;</p>
      <p>
        2. Identification of the material that has been removed or to which access
        has been disabled, and the location at which the material appeared before
        it was removed or access to it was disabled;
      </p>
      <p>
        3. A statement from you, under penalty of perjury, that you have a
        good-faith belief that the material was removed or disabled as a result of
        a mistake or misidentification of the material to be removed or disabled;
        and 4. Your name, physical address and telephone number, and a statement
        that you consent to the jurisdiction of a Federal District Court for the
        judicial district in which your physical address is located, or if your
        physical address is outside of the United States, for any judicial
        district in which we may be found, and that you will accept service of
        process from the person who provided notification of allegedly infringing
        material or an agent of such person.
      </p>
      <p>
        http://gundrymd.com’s Designated Agent for claims of copyright
        infringement can be reached at:
      </p>
      <p>[Re: Copyright Agent]</p>
      <p>
        [9465 Wilshire Boulevard, Suite 300,<br />
        Beverly Hills, CA, 90212, United States]
      </p>
      <a
        href="/cdn-cgi/l/email-protection#f685838686998482b691839892848f9b92d895999b"
        ><span
          class="__cf_email__"
          data-cfemail="47343237372835330720322923353e2a236924282a"
          >[email&#160;protected]</span
        ></a
      >
      <p>
        Note: The above contact information is provided exclusively for notifying
        http://gundrymd.com that copyright material may have been infringed. All
        other inquiries should be directed to our customer service by calling
        800-720-8403 or using our online submission form.
      </p>
      <h4 class="sub-title">INDEMNIFICATION</h4>
      <p>
        You agree to defend, indemnify and hold harmless http://gundrymd.com (and
        its officers, directors, agents, subsidiaries, joint ventures, employees
        and third-party service providers), from all claims, demands, losses,
        liabilities, costs, expenses, obligations and damages of every kind and
        nature, known and unknown, including reasonable legal fees, arising out of
        (a) your use of and access to the site; (b) your violation of any term of
        these Terms and Conditions; (c) a breach of these Terms and Conditions;
        (d) your violation of any law or the rights of a third party (including,
        without limitation, any copyright, property or privacy right); or (e) any
        claim that any content that you submitted to the site caused damage to a
        third party. This indemnification obligation will survive the termination
        of these Terms and Conditions and your use of the site.
      </p>
      <h4 class="sub-title">GOVERNING LAW AND JURISDICTION</h4>
      <p>
        These Terms and Conditions and all questions relating to the performance,
        interpretation, breach or enforcement of these Terms and Conditions, or
        the rights, obligations and liabilities of you and us under them are
        governed by the laws of the State of California.
      </p>
      <h4 class="sub-title">ARBITRATION</h4>
      <p>
        You agree that exclusive jurisdiction for any dispute, claim, or demand
        related in any way to the site will be decided by binding arbitration. All
        disputes between you and http://gundrymd.com of any kind or nature arising
        out of your use or enjoyment of the site, shall be submitted to Judicial
        Arbitration and Mediation Services, Inc. (“JAMS”) for binding arbitration
        under its rules then in effect in the State of California, before one
        arbitrator to be mutually agreed upon by both parties. The parties agree
        to share equally in the arbitration costs incurred. Any dispute resolution
        proceedings will be conducted only on an individual basis and not in a
        class, consolidated or representative action.
      </p>
      <h4 class="sub-title">INTERNATIONAL VISITORS AND SHIPPING</h4>
      <p>
        We make no representation that materials contained on the site or products
        described or offered on the site are appropriate or available for use in
        jurisdictions outside the United States, or that these Terms and
        Conditions comply with the laws of any other country. Visitors who use the
        site and reside outside the United States do so on their own initiative
        and are responsible for compliance with all laws, if and to the extent
        local laws are applicable. You agree that you will not access the site
        from any territory where its contents are illegal, and that you, and not
        http://gundrymd.com are responsible for compliance with applicable local
        laws.
      </p>
      <p>
        International orders may be subject to import taxes, customs duties and
        fees levied by your country’s customs department upon arrival. When
        ordering from http://gundrymd.comCosmetics.com, the recipient of the
        shipment is the importer of record and is responsible for any of these
        import fees, as well as complying with all laws and regulations of the
        destination country. http://gundrymd.com Cosmetics does not collect duties
        and taxes, and as smart as we are, we cannot predict what your particular
        charges may be. Customs policies vary widely from country to country, so
        please contact your local customs office for more information.
      </p>
      <h4 class="sub-title">FRAUD PROTECTION PROGRAM</h4>
      <p>
        As part of our order processing procedures, we screen all received orders
        for fraud or other types of unauthorized or illegal activity.
        http://gundrymd.com reserves the right to refuse to process an order or
        refund due to suspected fraud or unauthorized or illegal activity. If such
        is the case, http://gundrymd.com may reject your order or our Customer
        Service department may call you at the phone number you provided to
        confirm your order. We also reserve the right to cancel any accounts,
        refuse to ship to certain addresses, or withhold refunds or concessions
        due to suspected fraud or unauthorized or illegal activity.
        http://gundrymd.com takes these measures to protect http://gundrymd.com’s
        customers as well as http://gundrymd.com from fraud or other unauthorized
        or illegal activity.
      </p>
      <h4 class="sub-title">NO RESELLER ACTIVITY</h4>
      <p>
        The products and services available on the site, including any samples
        http://gundrymd.com may provide to you, are for personal use only. You may
        not sell or resell any of the products or services you purchase or
        otherwise receive from http://gundrymd.com. We reserve the right, with or
        without notice, to cancel or reduce the quantity of any order or withhold
        refunds or concessions, in our sole discretion, to those individuals we
        believe may be engaged in suspicious reseller activity or are otherwise
        violating these Terms and Conditions. Among other things, we may suspect
        reseller activity based on factors such as order frequency, amount and
        quantity.
      </p>
      <h4 class="sub-title">COMMENTS, FEEDBACK AND OTHER SUBMISSIONS</h4>
      <p>
        http://gundrymd.com welcomes your comments and feedback regarding the
        site, and http://gundrymd.com products and services. Any information,
        materials, suggestions, ideas or comments sent to http://gundrymd.com will
        not be treated as confidential, proprietary or trade secret information
        and, by submitting such information, you are granting http://gundrymd.com
        an irrevocable and unrestricted license to use, modify, reproduce,
        transmit, display and distribute such information for any purpose
        whatsoever. Unless required by law, http://gundrymd.com will not use your
        full name in connection with any such information, materials, suggestions,
        ideas or comments without your prior written consent.
      </p>
      <h4 class="sub-title">NO WAIVER</h4>
      <p>
        The failure of http://gundrymd.com to insist upon strict adherence to any
        term of these Terms and Conditions shall not constitute a waiver of such
        term. Further, it shall not impact http://gundrymd.com’s ability to
        enforce any other provision in these Terms and Conditions. You agree that
        regardless of any statute or law to the contrary, any claim of cause of
        action arising from or relating to use of the site or these Terms and
        Conditions must be filed within one (1) year after such claim or cause of
        action arose, or will be forever barred.
      </p>
      <h4 class="sub-title">SEVERABILITY</h4>
      <p>
        If any provision of these Terms and Conditions shall be deemed unlawful,
        void, or for any reason unenforceable by a court of competent
        jurisdiction, the validity and enforceability of any remaining provisions
        shall not be affected.
      </p>
    </div>
    <footer-common [links]="footerLinks"></footer-common>
</div>
